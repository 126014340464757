import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import {
  resetUserPassword,
  signInUser,
  signInWithGoogle,
} from "../../firebase";
import { startSession } from "../../storage/session";
import ResetPassword from "../ResetPassword/ResetPassword";
import "./Login.css";

export default function Login() {
  const [authData, setAuthData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [error, setError] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const navigate = useNavigate();

  const loginUser = (event) => {
    const { name, value } = event.target;
    setAuthData({ ...authData, [name]: value });
  };

  const submit = async () => {
    const loginResponse = await signInUser(authData);
    if (typeof loginResponse === "string") {
      setError(true);
    } else {
      startSession(loginResponse);
      navigate("/booking");
    }
  };

  const submitWithGoogle = async () => {
    const loginWithGoogleResponse = await signInWithGoogle();
    startSession(loginWithGoogleResponse.user);
    navigate("/booking");
  };

  const openResetPasswordModal = () => {
    setOpenResetPassword(true);
  };

  const closeResetPasswordModal = () => {
    setOpenResetPassword(false);
  };

  const handleForgetPassword = async (mail) => {
    if (mail) {
      await resetUserPassword(mail);
      closeResetPasswordModal();
      setResetPassword(true);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="logBox">
      <div className="loginForm">
        <div className="entryAndRegButton">
          <h3>
            <FormattedMessage id={"invitationToRegistration"} />
          </h3>
          <button className="buttonRegistration">
            <Link to={"/registration"}>
              <FormattedMessage id={"registration"} />
            </Link>
          </button>
        </div>
        <div className="loginContainer">
          <TextField
            className="loginInput"
            label={<FormattedMessage id={"login"} />}
            variant="filled"
            name="email"
            type="email"
            required
            onChange={loginUser}
          />
          <FormControl variant="filled" className="passwordFormLogin">
            <InputLabel htmlFor="filled-adornment-password">
              <FormattedMessage id={"password"} />
            </InputLabel>
            <FilledInput
              className="loginInput"
              id="filled-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={loginUser}
              name="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <Link
          to={"#"}
          onClick={openResetPasswordModal}
          className="forgotPasswordLink"
        >
          <FormattedMessage id={"forgotPassportLink"} />
        </Link>
        <button className="buttonLog" onClick={submit} type="submit">
          <FormattedMessage id={"entrance"} />
        </button>
        <button
          className="buttonLogWithGoogle"
          onClick={submitWithGoogle}
          type="submit"
        >
          <FormattedMessage id={"entranceWithGoogle"} />
        </button>
        {error ? (
          <Alert
            severity="warning"
            onClose={() => {
              setError(false);
            }}
            style={{ position: "absolute" }}
          >
            <FormattedMessage id={"errorLoginData"} />
          </Alert>
        ) : null}
        {resetPassword ? (
          <Alert
            severity="success"
            onClose={() => {
              setResetPassword(false);
            }}
            style={{
              position: "absolute",
              width: "30%",
              zIndex: 1,
              opacity: 0.7,
            }}
          >
            <AlertTitle>
              <FormattedMessage id={"resetPasswordInstructionsTitle"} />
            </AlertTitle>
            <FormattedMessage id={"resetPasswordInstructionsContent"} />
          </Alert>
        ) : null}
      </div>
      <ResetPassword
        openResetPassword={openResetPassword}
        handleForgetPassword={handleForgetPassword}
        closeResetPasswordModal={closeResetPasswordModal}
      />
    </div>
  );
}
