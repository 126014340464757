import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { questions } from "../../data";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";
import "./Course.css";

export default function Course() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `packages`;
    navigate(path);
  };

  return (
    <div>
      <div className="titleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorCourse" />
        </p>
        <p className="titleCourse">
          <span className="titleCourseColor">
            <FormattedMessage id="titleCourseColor" />
          </span>
          <FormattedMessage id="titleCourse" />
        </p>
      </div>
      <p className="aboutCourse">
        <FormattedMessage id="aboutCourse" />
      </p>
      {questions.map((question, index) => (
        <Accordion className="courseQuestionList" key={question.id}>
          <AccordionSummary
            className="courseQuestion"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div className="questionContainer">
              <span className="questionNum">{"0" + (index + 1)}</span>
              <span className="questionTitle">
                <FormattedMessage id={question.title} />
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="questionDescription">
            <FormattedMessage id={question.description} />
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="courseBtnBox" onClick={routeChange}>
        <СontainedButton>
          <FormattedMessage id="courseButton" />
        </СontainedButton>
      </div>
    </div>
  );
}
