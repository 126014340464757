// export const practices = [
//   {
//     id: "1",
//     type: "individual",
//   },
//   {
//     id: "2",
//     type: "group",
//   },
// ];

import group from "../assets/groupPractice.jpg";
import personal from "../assets/personalPractice.jpg";
import prenatal from "../assets/prenatalPractice.png";
import kids from "../assets/kidsPractice.JPG";

export const practices = [
  { title: "practice_group", img: group, description: "practice_2" },
  { title: "practice_individual", img: personal, description: "practice_1" },
  { title: "practice_prenatal", img: prenatal, description: "practice_3" },
  { title: "practice_kids", img: kids, description: "practice_4" },
];
