import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import coursePackages from "../../assets/coursePackages.jpg";
import whitePoint from "../../assets/whitePoint.svg";
import { courseAdvantages, packages } from "../../data";
import NavigationMenu from "../NavigatonMenu/NavigationMenu";
import "./CoursePackages.css";

export default function CoursePackages({
  currentLocale,
  handleChange,
  responsive,
  mobileView,
  browserView,
}) {
  return (
    <div className="coursePackagesContainer">
      <NavigationMenu
        currentLocale={currentLocale}
        handleChange={handleChange}
        browserView={browserView}
        mobileView={mobileView}
      />
      <div className="coursePackagesContent">
        <div className="titlePackagesBox">
          <div className="descriptor">
            <FormattedMessage id="descriptorCourse" />
          </div>
          <p className="titlePackages">
            <span>
              <FormattedMessage id="titlePackagesFirst" />
            </span>
            <span className="titlePackagesColor">
              <FormattedMessage id="titlePackagesColor" />
            </span>
            <FormattedMessage id="titlePackagesLast" />
          </p>
        </div>
        <div className="coursePackagesBox">
          {browserView && (
            <img src={coursePackages} className="coursePackagesImg" alt="..." />
          )}
          <List component="nav" className="coursePackagesList">
            {courseAdvantages.map((advantage, index) => (
              <ListItemButton
                key={"packageItem" + index}
                className="packageItem"
              >
                <ListItemText className="packageItemPoint">
                  <img src={whitePoint} alt="..." />
                </ListItemText>

                <ListItemText
                  primary={<FormattedMessage id={advantage.title} />}
                  className="packageItemText"
                />
              </ListItemButton>
            ))}
          </List>
        </div>
        <div className="titlePackagesBox">
          <div className="descriptor">
            <FormattedMessage id="descriptorTariffs" />
          </div>
          <p className="titleTariff">
            <span>
              <FormattedMessage id="titleTariffsFirst" />
            </span>
            <span className="titlePackagesColor">
              <FormattedMessage id="titleTariffsColor" />
            </span>
          </p>
        </div>

        {browserView && (
          <div className="tariffCardsBox">
            {packages.map((pack) => (
              <Card
                key={pack.id}
                className={
                  pack.id === 2 ? "tariffCard activeCard" : "tariffCard"
                }
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="tariffCardTitle"
                  >
                    <FormattedMessage id={`${pack.title}`} />
                  </Typography>
                  <CardActions className="tariffCardBody">
                    <Typography
                      variant="body2"
                      className="tariffCardDescription"
                    >
                      <FormattedMessage id={pack.description} />
                    </Typography>
                  </CardActions>
                </CardContent>
                <CardActions className="tariffCardFooter">
                  <Typography variant="body2" className="tariffCardPrice">
                    <FormattedMessage id={pack.price} />
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    className="buttonInTariffCard"
                    as="a"
                    href={`https://wa.me/79265406815?text=Здравствуйте,+я+хочу+приобрести+курс+за+${pack.cost}`}
                    target="_blank"
                  >
                    <FormattedMessage id={"tariffButton"} />
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        )}
        {mobileView && (
          <div className="tariffCardsBox">
            <Carousel
              swipeable
              draggable={false}
              responsive={responsive}
              ssr={false}
              infinite
              autoPlay={false}
              partialVisible
              keyBoardControl
              containerClass="carousel-container"
              removeArrowOnDeviceType={["mobile"]}
            >
              {packages.map((pack) => (
                <Card
                  key={pack.id}
                  className={
                    pack.id === 2 ? "tariffCard activeCard" : "tariffCard"
                  }
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="tariffCardTitle"
                    >
                      <FormattedMessage id={`${pack.title}`} />
                    </Typography>
                    <CardActions className="tariffCardBody">
                      <Typography
                        variant="body2"
                        className="tariffCardDescription"
                      >
                        <FormattedMessage id={pack.description} />
                      </Typography>
                    </CardActions>
                  </CardContent>
                  <CardActions className="tariffCardFooter">
                    <Typography variant="body2" className="tariffCardPrice">
                      <FormattedMessage id={pack.price} />
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      className="buttonInTariffCard"
                      as="a"
                      href={`https://wa.me/79265406815?text=Здравствуйте,+я+хочу+приобрести+курс+за+${pack.cost}`}
                      target="_blank"
                    >
                      <FormattedMessage id={"tariffButton"} />
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
}
