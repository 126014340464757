import HomeIcon from "@mui/icons-material/Home";
import { useState, useMemo, useEffect } from "react";
import { IntlProvider } from "react-intl";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Admin from "./components/Admin/Admin";
import Booking from "./components/Booking/Booking";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import PersonalCabinet from "./components/PersonalCabinet/PersonalCabinet";
import Registration from "./components/Registration/Registration";
import VideoCourse from "./components/VideoCourse/VideoCourse";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import { endSession, getSession } from "./storage/session";
import CoursePackages from "./components/CoursePackages/CoursePackages";
import { RetreatModal } from "./components/RetreatModal/RetreatModal";
import Bowser from "bowser";

let mobileView = false;
let browserView = false;
const minWidth = 768;

function App() {
  const [currentLocale, setCurrentLocale] = useState(getInitialLocale());
  const [openPromotion, setOpenPromotion] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = ({ target: { value } }) => {
    setCurrentLocale(value);
    localStorage.setItem("locale", value);
  };

  function getInitialLocale() {
    const savedLocale = localStorage.getItem("locale");
    return savedLocale || LOCALES.ENGLISH;
  }

  const user = useMemo(() => getSession(), []);

  const onLogout = () => {
    endSession();
    navigate("/login");
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 440, min: 360 },
      items: 1,
      partialVisibilityGutter: 70,
    },
  };

  const parser = Bowser.getParser(navigator.userAgent);

  if (parser.getPlatformType() === "mobile" || window.innerWidth < minWidth) {
    mobileView = true;
  } else {
    browserView = true;
  }

  const openPromotionModal = () => {
    setOpenPromotion(true);
  };

  const closePromotionModal = () => {
    setOpenPromotion(false);
  };

  useEffect(() => {
    openPromotionModal();
  }, []);

  return (
    <IntlProvider
      locale="en"
      messages={messages[currentLocale]}
      key={currentLocale}
    >
      <div>
        {location.pathname !== "/" && (
          <div className="buttonMainMenu">
            <Link to={"/"}>
              <button className="buttonHomeMainPage">
                <HomeIcon />
              </button>
            </Link>
          </div>
        )}
        <RetreatModal
          openPromotion={openPromotion}
          closePromotionModal={closePromotionModal}
        />
        <Routes>
          {user.uid === "MOHRB8XMNFetVsAU4GTwW2NvCDK2" ||
          user.uid === "fJPswP2SjtNHpiBCZ350ZBbqBoR2" ? (
            <Route path="/admin" element={<Admin />} />
          ) : (
            ""
          )}
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <Home
                currentLocale={currentLocale}
                handleChange={handleChange}
                responsive={responsive}
                mobileView={mobileView}
                browserView={browserView}
              />
            }
          />
          <Route path="/cabinet" element={<PersonalCabinet />} />
          <Route path="/booking" element={<Booking />} />
          <Route
            path="/packages"
            element={
              <CoursePackages
                currentLocale={currentLocale}
                handleChange={handleChange}
                responsive={responsive}
                mobileView={mobileView}
                browserView={browserView}
              />
            }
          />
          <Route
            path="/course"
            element={
              <VideoCourse
                currentLocale={currentLocale}
                handleChange={handleChange}
                mobileView={mobileView}
                browserView={browserView}
                responsive={responsive}
              />
            }
          />
        </Routes>
      </div>
    </IntlProvider>
  );
}

export default App;
