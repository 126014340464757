import { createDocument } from "../firebase";

export const createUserMessage = async (messageData, id) => {
  try {
    const data = await createDocument("userMessage", {
      email: messageData.mail,
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};
