import { getDocuments } from "../firebase";

export const showAvailableTime = async () => {
  try {
    const [data] = await getDocuments("schedule", false);

    const schedule = {};

    Object.keys(data).forEach((day) => {
      Object.entries(data[day]).forEach(
        ([time, [personCount, groupType, trainingType, format]]) => {
          if (!schedule[day]) {
            schedule[day] = [{ time, personCount, groupType, trainingType, format }];
          } else {
            schedule[day].push({ time, personCount, groupType, trainingType, format });
          }
        }
      );
    });

    return schedule;
  } catch (error) {
    console.error(error);
  }
};
