export const footerNavigationSections = [
  {
    id: "course",
    name: "Course",
  },
  {
    id: "practices",
    name: "Practices",
  },
  {
    id: "tours",
    name: "Tours",
  },
  {
    id: "meditation",
    name: "Meditation",
  },
  {
    id: "reviews",
    name: "Reviews",
  },
];
