import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import ReactPlayer from "react-player";
import {
  addVideo,
  breathVideo,
  mainVideoFirstGroup,
  mainVideoForMobile,
  mainVideoSecondGroup,
  mainVideoThirdGroup,
  workshopVideo,
} from "../../data/index";
import NavigationMenu from "../NavigatonMenu/NavigationMenu";
import "./VideoCourse.css";

export default function Video({
  currentLocale,
  handleChange,
  mobileView,
  browserView,
  responsive,
}) {
  return (
    <div className="videoMainContainer">
      <NavigationMenu
        currentLocale={currentLocale}
        handleChange={handleChange}
        browserView={browserView}
        mobileView={mobileView}
      />
      <div className="videoCourseContent">
        <p className="titleVideoCourse">
          <span>
            <FormattedMessage id="videoCourseTitle" />
          </span>
          <span className="titleVideoCourseColor">
            <FormattedMessage id="videoCourseTitleColor" />
          </span>
          <FormattedMessage id="videoCourseTitleLast" />
        </p>
        <div className="introductionVideo">
          <ReactPlayer
            url="http://www.youtube.com/embed/KovlyQILtR0?si=-zCPD0KqaJCwOVWX"
            controls={true}
          />
        </div>
        <div className="addVideoContainer">
          <div className="addVideoTitleBox">
            <p className="descriptor">
              <FormattedMessage id="descriptorAddVideo" />
            </p>
            <p className="titleAddVideo">
              <span>
                <FormattedMessage id="titleAddVideoFirst" />
              </span>
              <span className="titleVideoCourseColor">
                <FormattedMessage id="titleAddVideoColor" />
              </span>
              <FormattedMessage id="titleAddVideo" />
            </p>
          </div>
          <div className="addVideoBox">
            {addVideo.map((addV) => (
              <button className="addVideoBtn" key={addV.id}>
                <a href={addV.link} target="_blank" rel="noreferrer">
                  <FormattedMessage id={addV.title} />
                </a>
              </button>
            ))}
          </div>
        </div>
        <div className="breathVideoContainer">
          <div className="breathVideoTitleBox">
            <p className="descriptor">
              <FormattedMessage id="descriptorBreathVideo" />
            </p>
            <p className="titleBreathVideo">
              <span>
                <FormattedMessage id="titleBreathVideoFirst" />
              </span>
              <span className="titleVideoCourseColor">
                <FormattedMessage id="titleBreathVideoColor" />
              </span>
            </p>
          </div>
          <div className="breathVideoBox">
            {breathVideo.map((bVideo) => (
              <button className="breathVideoBtn" key={bVideo.id}>
                <a href={bVideo.link} target="_blank" rel="noreferrer">
                  <FormattedMessage id={bVideo.title} />
                </a>
              </button>
            ))}
          </div>
        </div>
        <div className="mainVideoContainer">
          <div className="mainVideoTitleBox">
            <p className="descriptor">
              <FormattedMessage id="descriptorMainVideo" />
            </p>
            <p className="titleMainVideo">
              <span>
                <FormattedMessage id="titleMainVideoFirst" />
              </span>
              <span className="titleVideoCourseColor">
                <FormattedMessage id="titleMainVideoColor" />
              </span>
              <FormattedMessage id="titleMainVideo" />
            </p>
          </div>
          <p className="mainVideoText">
            <FormattedMessage id="mainVideoText" />
          </p>
          {mobileView && (
            <Carousel
              swipeable={true}
              draggable={false}
              responsive={responsive}
              ssr={false}
              infinite={true}
              autoPlay={false}
              partialVisible={true}
              keyBoardControl={true}
              containerClass="carousel-container"
              itemClass="videoCourseCarousel"
              removeArrowOnDeviceType={["mobile"]}
            >
              {mainVideoForMobile.map((courseV) => (
                <div key={"" + courseV.id} className="videoCourseCardContent">
                  <Card
                    className="videoCourseCardMobile"
                    key={"videoCourseCard" + courseV.id}
                    style={{ backgroundImage: `url(${courseV.img})` }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="videoCourseTitle"
                      >
                        <FormattedMessage id={`${courseV.title}`} />
                      </Typography>
                      <Typography
                        variant="body2"
                        className="videoCourseCardDescription"
                      >
                        <span>
                          <FormattedMessage id={courseV.oils} />
                        </span>
                        <span>
                          <FormattedMessage id={courseV.breath} />
                        </span>
                      </Typography>
                    </CardContent>
                    <CardActions className="videoCourseCardFooter">
                      <a href={courseV.link} target="_blank" rel="noreferrer">
                        <Button
                          size="small"
                          variant="contained"
                          className="buttonInvideoCourseCard"
                        >
                          <FormattedMessage id={courseV.btnTitle} />
                        </Button>
                      </a>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </Carousel>
          )}
          {browserView && (
            <div className="mainVideoBox">
              <div className="videoFirstGroup">
                {mainVideoFirstGroup.map((vFirst) => (
                  <div
                    key={vFirst.id}
                    style={{ backgroundImage: `url(${vFirst.img})` }}
                    className="videoCourseCard"
                  >
                    <span>
                      <p className="videoCourseCardTitle">
                        <FormattedMessage id={vFirst.title} />
                      </p>
                      <p className="oilsAndBreathDescription">
                        <FormattedMessage id={vFirst.oils} />
                      </p>
                      <p className="oilsAndBreathDescription">
                        <FormattedMessage id={vFirst.breath} />
                      </p>
                    </span>
                    <span>
                      <a href={vFirst.link} target="_blank" rel="noreferrer">
                        <button className="startVideoBtn">
                          <FormattedMessage id={vFirst.btnTitle} />
                        </button>
                      </a>
                    </span>
                  </div>
                ))}
              </div>
              <div className="videoSecondGroup">
                {mainVideoSecondGroup.map((vSecond) => (
                  <div
                    key={vSecond.id}
                    style={{ backgroundImage: `url(${vSecond.img})` }}
                    className="videoCourseCard"
                  >
                    <span>
                      <p className="videoCourseCardTitle">
                        <FormattedMessage id={vSecond.title} />
                      </p>
                      <p className="oilsAndBreathDescription">
                        <FormattedMessage id={vSecond.oils} />
                      </p>
                      <p className="oilsAndBreathDescription">
                        <FormattedMessage id={vSecond.breath} />
                      </p>
                    </span>
                    <span>
                      <a href={vSecond.link} target="_blank" rel="noreferrer">
                        <button className="startVideoBtn">
                          <FormattedMessage id={vSecond.btnTitle} />
                        </button>
                      </a>
                    </span>
                  </div>
                ))}
              </div>
              <div className="videoThirdGroup">
                {mainVideoThirdGroup.map((vThird) => (
                  <div
                    key={vThird.id}
                    style={{ backgroundImage: `url(${vThird.img})` }}
                    className="videoCourseCard"
                  >
                    <span>
                      <p className="videoCourseCardTitle">
                        <FormattedMessage id={vThird.title} />
                      </p>
                      <p className="oilsAndBreathDescription">
                        <FormattedMessage id={vThird.oils} />
                      </p>
                      <p className="oilsAndBreathDescription">
                        <FormattedMessage id={vThird.breath} />
                      </p>
                    </span>
                    <span>
                      <a href={vThird.link} target="_blank" rel="noreferrer">
                        <button className="startVideoBtn">
                          <FormattedMessage id={vThird.btnTitle} />
                        </button>
                      </a>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="workshopVideoContainer">
            <div className="workshopVideoTitleBox">
              <p className="descriptor">
                <FormattedMessage id="descriptorWorkshopVideo" />
              </p>
              <p className="titleWorkshopVideo">
                <span>
                  <FormattedMessage id="titleWorkshopVideo" />
                </span>
              </p>
            </div>
            <div className="workshopVideoBox">
              {workshopVideo.map((workshopV) => (
                <button className="workshopVideoBtn" key={workshopV.id}>
                  <a href={workshopV.link} target="_blank" rel="noreferrer">
                    <FormattedMessage id={workshopV.title} />
                  </a>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
