import bodyAndMind from "../assets/bodyAndMind.jpg";
import personalRetreat from "../assets/personalRetreat.jpg";
import justYoga from "../assets/justYoga.jpg";

export const tours = [
  {
    id: 1,
    title: "toursPersonalTitle",
    duration: "toursDurationPersonal",
    description: "toursPersonalDescription",
    addInfo: "toursPersonalAddInfo",
    img: personalRetreat,
    show: false,
  },
  {
    id: 2,
    title: "toursSoulTitle",
    duration: "toursSoulDuration",
    description: "toursSoulDescription",
    addInfo: "toursSoulAddInfo",
    img: bodyAndMind,
    show: false,
  },
  {
    id: 3,
    title: "toursBaseTitle",
    duration: "toursBaseDuration",
    description: "toursBaseDescription",
    addInfo: "toursBaseAddInfo",
    img: justYoga,
    show: false,
  },
];
