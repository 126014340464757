import React from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "../Buttons/TextButton/TextButton";
import "./Corporate.css";

export default function Corporate() {
  return (
    <div className="corporateContainer">
      <div className="corporateTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorCorporate" />
        </p>
        <p className="titleCorporate">
          <div className="titleCorporateFirstPart">
            <span className="titleCorporateFirst">
              <FormattedMessage id="titleCorporateFirst" />
            </span>
            <span className="titleCorporateColor">
              <FormattedMessage id="titleCorporateColor" />
            </span>
            <span>
              <FormattedMessage id="titleCorporateSecond" />
            </span>
          </div>
          <div className="titleCorporateSecondPart">
            <FormattedMessage id="titleCorporate" />
          </div>
        </p>
      </div>
      <div className="corporeteMainText">
        <div className="firstTextGroup">
          <span>
            <FormattedMessage id="corporateFirstText" />
          </span>
          <span>
            <FormattedMessage id="corporateSecondText" />
          </span>
        </div>
        <span className="secondTextGroup">
          <FormattedMessage id="corporateThirdText" />
        </span>
      </div>
      <TextButton>
        <a
          href={`https://wa.me/79030031642?text=Здравствуйте,+я+хочу+узнать+о+корпоративном+сотрудничестве`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="corporateButton" />
        </a>
      </TextButton>
    </div>
  );
}
