import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import "./ResetPassword.css";

export default function ResetPassword({
  openResetPassword,
  handleForgetPassword,
  closeResetPasswordModal,
}) {
  const [mail, setMail] = useState("");
  const [mailError, setMailError] = useState("");

  const handleReset = () => {
    if (!mail) {
      setMailError(<FormattedMessage id={"incorrectMailErrorEmpty"} />);
      return;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail)) {
      setMailError(<FormattedMessage id={"incorrectMailErrorValid"} />);
      return;
    }
    handleForgetPassword(mail);
    setMail("");
    setMailError("");
  };

  const onClose = () => {
    closeResetPasswordModal();
    setMail("");
    setMailError("");
  };

  return (
    <div>
      <Dialog open={openResetPassword} onClose={onClose}>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id={"resetPasswordText"} />
          </DialogContentText>
          <TextField
            className="resetPasswordForm"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            label={<FormattedMessage id={"login"} />}
            type="email"
            fullWidth
            variant="standard"
          />
          {mailError && <p className="incorrectResetEmail">{mailError}</p>}
        </DialogContent>
        <DialogActions className="resetEmailBtns">
          <Button onClick={onClose}>
            <FormattedMessage id={"resetPasswordCancel"} />
          </Button>
          <Button onClick={handleReset}>
            <FormattedMessage id={"resetPasswordSend"} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
