import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import essentialOilsGuide from "../../oils.pdf";
import dictionaryGuide from "../../dictionary.pdf";
import chackrasGuide from "../../chackras.pdf";
import "./ModalGuides.css";

export const CardModal = ({
  file,
  onClose,
  onLoadSuccess,
  pageNumber,
  numPages,
  previousPage,
  nextPage,
}) => {
  if (!file) return null;

  const files = { essentialOilsGuide, dictionaryGuide, chackrasGuide };

  return (
    <Modal
      open={!!file}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="guideBox">
        <Document file={files[file]} onLoadSuccess={onLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div className="pageInPdf">
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </Box>
    </Modal>
  );
};
