import { firebaseDB } from "./firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

async function createDocument(dbCollection, data) {
  return await addDoc(collection(firebaseDB, dbCollection), data);
}

async function deleteDocument(dbCollection, id) {
  await deleteDoc(doc(firebaseDB, dbCollection, id));
}

async function getDocuments(dbCollection, isWithCollectionId = true) {
  const snapshot = await getDocs(collection(firebaseDB, dbCollection));

  const data = [];

  snapshot.forEach((doc) => {
    if (isWithCollectionId) {
      data.push({ id: doc.id, ...doc.data() });
    } else {
      data.push(doc.data());
    }
  });

  return data;
}

export { getDocuments, createDocument, deleteDocument };
