export const startSession = (user) => {
  sessionStorage.setItem("email", user.email);
  sessionStorage.setItem("displayName", user.displayName);
  sessionStorage.setItem("uid", user.uid);
  sessionStorage.setItem("accessToken", user.accessToken);
  sessionStorage.setItem("photo", user?.photoURL);
};

export const getSession = () => {
  return {
    uid: sessionStorage.getItem("uid"),
    email: sessionStorage.getItem("email"),
    accessToken: sessionStorage.getItem("accessToken"),
    displayName: sessionStorage.getItem("displayName"),
    photo: sessionStorage.getItem("photo"),
  };
};

export const endSession = () => {
  sessionStorage.clear();
};

export const isLoggedIn = () => {
  return getSession().accessToken;
};
