import React from "react";
import Cover from "../Cover/Cover";
import About from "../About/About";
import Action from "../Action/Action";
import Advantages from "../Advantages/Advantages";
import Certificates from "../Certificates/Certificates";
import Corporate from "../Corporate/Corporate";
import Course from "../Course/Course";
import EssentialOils from "../EssentialOils/EssentialOils";
import Footer from "../Footer/Footer";
import Meditation from "../Meditation/Meditation";
import Practices from "../Practices/Practices";
import Prices from "../Prices/Prices";
import Reviews from "../Reviews/Reviews";
import Tours from "../Tours/Tours";
import "./Home.css";

export default function Home({
  responsive,
  currentLocale,
  handleChange,
  mobileView,
  browserView,
}) {
  return (
    <div className="homePageMain">
      <div className="coverBlock" id="coverBlock">
        <Cover
          currentLocale={currentLocale}
          handleChange={handleChange}
          mobileView={mobileView}
          browserView={browserView}
        />
      </div>
      <div className="sectionsContainer">
        <div className="advantages" id="advantages">
          <Advantages browserView={browserView} />
        </div>
        <div className="practices" id="practices">
          <Practices
            responsive={responsive}
            browserView={browserView}
            mobileView={mobileView}
          />
        </div>
        <div className="course" id="course">
          <Course />
        </div>
        <div className="meditation" id="meditation">
          <Meditation />
        </div>
        <div className="tours">
          <Tours browserView={browserView} />
        </div>
        <div className="corporate">
          <Corporate />
        </div>
        <div className="essentialOils">
          <EssentialOils />
        </div>
        <div className="prices" id="prices">
          <Prices responsive={responsive} />
        </div>
        <div className="about" id="about">
          <About mobileView={mobileView} browserView={browserView} />
        </div>
        <div className="certificates" id="certificates">
          <Certificates />
        </div>
        <div className="reviews" id="reviews">
          <Reviews />
        </div>
        <div className="action" id="action">
          <Action browserView={browserView} mobileView={mobileView} />
        </div>
        <div className="footer">
          <Footer browserView={browserView} mobileView={mobileView} />
        </div>
      </div>
    </div>
  );
}
