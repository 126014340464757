import React from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Prices.css";
import Button from "@mui/material/Button";
import { practicesType } from "../../data/index";

export default function Prices({ responsive }) {
  return (
    <div>
      <div className="pricesTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorPrices" />
        </p>
        <p className="titlePrices">
          <span>
            <FormattedMessage id="titlePricesFirst" />
          </span>
          <span className="titlePricesColor">
            <FormattedMessage id="titlePricesColor" />
          </span>
          <FormattedMessage id="titlePrices" />
        </p>
      </div>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={false}
        infinite={true}
        autoPlay={false}
        partialVisible={true}
        keyBoardControl={true}
        containerClass="carousel-container"
        itemClass="priceCard"
        removeArrowOnDeviceType={["mobile"]}
      >
        {practicesType.map((practice) => (
          <div key={"practice" + practice.id} className="priceCardContent">
            <div className="priceCardText">
              <span className="priceCardTitle">
                <FormattedMessage id={practice.title} />
              </span>
              <span className="priceCardFirstText">
                <FormattedMessage id={practice.title + "Duration"} />
              </span>
              <span className="priceCardSecondText">
                <FormattedMessage id={practice.title + "Price"} />
              </span>
            </div>
            <div className="buttonInPriceCardContainer">
              <Button
                size="small"
                variant="contained"
                className="buttonInPriceCard"
                as="a"
                href={`https://wa.me/79030031642?text=Здравствуйте,+я+хочу+оплатить+${practice.titleForPay}+за+${practice.cost}`}
                target="_blank"
              >
                <FormattedMessage id={"buttonInPriceCard"} />
              </Button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
