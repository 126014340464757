import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { tours } from "../../data";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";
import "./Tours.css";

export default function Tours({ browserView }) {
  const [images, setImages] = useState(tours);

  const showImage = (id) => {
    setImages((prevImages) => {
      return prevImages.map((img) => {
        if (img.id === id) {
          img.show = !img.show;
        } else {
          img.show = false;
        }

        return img;
      });
    });
  };

  return (
    <div className="toursContainer" id="tours">
      <div className="toursTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorTours" />
        </p>
        <p className="titleTours">
          <div className="titleToursFirst">
            <span>
              <FormattedMessage id="titleToursFirst" />
            </span>
            <span className="titleToursColor">
              <FormattedMessage id="titleToursColor1" />
            </span>
          </div>
          <div className="titleToursSecond">
            <span className="titleToursColor">
              <FormattedMessage id="titleToursColor2" />
            </span>
            <span>
              <FormattedMessage id="titleTours" />
            </span>
          </div>
        </p>
      </div>
      <div className="toursList">
        {images.map((tour) => (
          <div
            key={tour.id}
            className={tour.show ? "toursBlockFull" : "toursBlock"}
          >
            {tour.show && browserView ? (
              <img src={tour.img} alt="..." className="imgInTour" />
            ) : (
              ""
            )}
            <Accordion expanded={tour.show} className="toursOfferList">
              <AccordionSummary
                className="tourOfferTitleContainer"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => showImage(tour.id)}
              >
                <span className="tourOfferTitle">
                  <FormattedMessage id={tour.title} />
                </span>
              </AccordionSummary>
              <AccordionDetails className="tourOfferDescriptionContainer">
                <div className="tourOfferDescription">
                  <span>
                    <FormattedMessage id={tour.duration} />
                  </span>
                  <span className="tourMainDescription">
                    <FormattedMessage id={tour.description} />
                  </span>
                  <span className="tourAddDescription">
                    <FormattedMessage id={tour.addInfo} />
                  </span>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
      <СontainedButton>
        <a
          href={`https://wa.me/79030031642?text=Здравствуйте,+я+хочу+узнать+подробнее+о+турах`}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="corporateButton" />
        </a>
      </СontainedButton>
    </div>
  );
}
