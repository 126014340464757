import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getAuth, updateProfile } from "firebase/auth";
import { storage } from ".";

export function useAddUserPhoto() {
  function handleUpload(event, cb) {
    const file = event.target.files[0];
    if (!file) {
      alert("Please choose a file first!");
    }

    const imageRef = ref(storage, "images/" + file.name);

    const metadata = {
      contentType: "image/jpeg",
    };

    uploadBytes(imageRef, file, metadata).then(() => {
      getDownloadURL(imageRef).then((url) => {
        cb?.(url);

        const auth = getAuth();
        updateProfile(auth.currentUser, {
          photoURL: url,
        });

        sessionStorage.setItem("photo", url);
      });
    });
  }
  return { handleUpload };
}
