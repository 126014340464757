import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import { FormattedMessage } from "react-intl";
import photo from "../../assets/coursePackages.jpg";
import "./RetreatModal.css";

export const RetreatModal = ({ openPromotion, closePromotionModal }) => {

  return (
    <Modal
      open={openPromotion}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="retreatBox"
        style={{
          backgroundImage: `url(${photo})`,
        }}
      >
        <div className="closeIconPromotion">
          <CloseIcon onClick={closePromotionModal} />
        </div>
        <div className="retreatInfoContainer">
          <div className="promotionTitle">
            <FormattedMessage id={"promotionTitle"} />
          </div>
          <div className="promotionSubTitle">
            <FormattedMessage id={"promotionSubTitle"} />
          </div>
          <a
            href="https://onesummertravel.com/mdanilovich"
            className="promotionButton"
          >
            <FormattedMessage id={"promotionButton"} />
          </a>
        </div>
      </Box>
    </Modal>
  );
};
