import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { updateProfile } from "firebase/auth";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { createUser, signInWithGoogle } from "../../firebase";
import { startSession } from "../../storage/session";
import "./Registration.css";

export default function Registration() {
  const navigate = useNavigate();
  const [registrationData, setRegistrationData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const regUser = (event) => {
    const { name, value } = event.target;
    setError("");
    setRegistrationData({ ...registrationData, [name]: value });

    // Validate password
    if (registrationData.password) {
      if (registrationData.password.length < 6) {
        setError(<FormattedMessage id={"incorrectPasswordErrorLength"} />);
        return;
      } else if (!/[A-Z]/.test(registrationData.password)) {
        setError(<FormattedMessage id={"incorrectPasswordErrorLetter"} />);
        return;
      }
    }
  };

  const submit = async () => {
    try {
      const registerResponse = await createUser(registrationData);
      await updateProfile(registerResponse.user, {
        displayName: registrationData.name,
      });
      startSession(registerResponse.user);
      navigate("/");
    } catch (error) {
      console.error(error.message);
    }
  };

  const submitWithGoogle = async () => {
    const regWithGoogleResponse = await signInWithGoogle();
    startSession(regWithGoogleResponse.user);
    navigate("/booking");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="regBox">
      <div className="regForm">
        <div className="entryAndLogButton">
          <h3>
            <FormattedMessage id={"invitationToLogin"} />
          </h3>
          <button className="buttonRegistration">
            <Link to={"/login"}>
              <FormattedMessage id={"entrance"} />
            </Link>
          </button>
        </div>
        <div className="registrationContainer">
          <TextField
            className="registrationInput"
            label={<FormattedMessage id={"name"} />}
            variant="filled"
            name="name"
            required
            onChange={regUser}
          />
          <TextField
            className="registrationInput"
            label={<FormattedMessage id={"login"} />}
            variant="filled"
            name="email"
            type="email"
            required
            onChange={regUser}
          />
          <FormControl variant="filled" className="passwordForm">
            <InputLabel htmlFor="filled-adornment-password">
              <FormattedMessage id={"password"} />
            </InputLabel>
            <FilledInput
              className="registrationInput"
              id="filled-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={regUser}
              name="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        {error && <p className="incorrectPasswordMessage">{error}</p>}
        <button className="buttonReg" onClick={submit} type="submit">
          <Link to={"/cabinet"}>
            <FormattedMessage id={"registrationButton"} />
          </Link>
        </button>
        <button className="buttonRegWithGoogle" onClick={submitWithGoogle}>
          <FormattedMessage id={"entranceWithGoogle"} />
        </button>
      </div>
    </div>
  );
}
