import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    descriptorAdvantages: "Advantages",
    advantagesFirst: "Special approach",
    advantagesSecond: "Different types of practices",
    advantagesThird: "Yoga community chat support",
    advantagesFourth: "Possibility to study online or in the studio",
    advantagesFifth: "Conducting retreats",
    coverTitle: "dariya yoga",
    coverText:
      "Personal and group practices, online and offline classes, yoga for pregnant women, retreats and yoga bachelorette parties around the world.",
    introductionTitle: "Why me?",
    introductionExperience: "4+ years of practice",
    introductionPersonalApproach: "Individual approach to each client",
    introductionCommunity: "Great yoga community of happy people",
    aboutMeFirst:
      "My name is Daria, I am 31 years old. Now I live in France, I spent 3 years in Switzerland, I am constantly charged with the energy of the Alps, inspired by nature, the elements and the beauty around me, and I am also a mother of two children!",
    aboutMeSecond:
      "Practicing different styles of yoga, my unique style was born, in which you will find more dynamic exercises, back exercises, relaxation, stretching, as well as traditional balance asanas and breathing practices.",
    descriptorPrices: "Prices and subscriptions",
    titlePricesFirst:
      "You can purchase a one-time visit or a subscription at a ",
    titlePricesColor: "better price ",
    titlePrices: "for online group practices.",
    pricesPersonalLesson: "Personal lesson",
    pricesPersonalLessonDuration: "60 minutes",
    pricesPersonalLessonPrice: "100€",
    pricesPersonalLessonZoom: "Personal lesson in zoom",
    pricesPersonalLessonZoomDuration: "60 minutes",
    pricesPersonalLessonZoomPrice: "70€",
    pricesPrenatal: "Personal Prenatal lesson",
    pricesPrenatalDuration: "60 minutes",
    pricesPrenatalPrice: "100€",
    pricesPrenatalZoom: "Personal Prenatal lesson in zoom",
    pricesPrenatalZoomDuration: "60 minutes",
    pricesPrenatalZoomPrice: "70€",
    pricesGroupPractice: "Group class",
    pricesGroupPracticeDuration: "60 minutes",
    pricesGroupPracticePrice: "40€",
    pricesGroupPracticeZoom: "Group class on Zoom",
    pricesGroupPracticeZoomDuration: "60 minutes",
    pricesGroupPracticeZoomPrice: "25€",
    subscription4practices: "4 class subscription",
    subscription4practicesPrice: "80€",
    subscription4practicesDuration: "60 minutes",
    subscription8practices: "8 class subscription",
    subscription8practicesPrice: "150€",
    subscription8practicesDuration: "60 minutes",
    subscription12practices: "subscription for 12 classes",
    subscription12practicesPrice: "180€",
    subscription12practicesDuration: "60 minutes",
    subscription16practices: "subscription for 16 classes",
    subscription16practicesPrice: "200€",
    subscription16practicesDuration: "60 minutes",
    buttonInPriceCard: "Pay",
    descriptorAbout: "About the teacher",
    titleAboutFirst: "My name is Daria, my journey in yoga began ",
    titleAboutColor: "in India, ",
    titleAbout:
      "where, studying various directions, a unique style was born in which you will find something that is close to you.",
    aboutFirstText:
      "Living in France, I am constantly charged with the energy of the Alps, inspired by nature, the elements and the beauty around me, thanks to which I create unique retreats, conduct workshops and motivate people to do yoga, and I am also a mother of two children!",
    aboutSecondText:
      "In my practices you will find dynamic exercises, back exercises, relaxation, stretching, as well as traditional balance asanas and breathing practices.",
    descriptorTours: "Outdoor tours and retreats",
    titleToursFirst:
      "You will master breathing practices and meditation, learn about ",
    titleToursColor1: "the yogic ",
    titleToursColor2: "lifestyle, ",
    titleTours: "introduce new healthy habits, build asanas.",
    toursPersonalTitle: "Personal retreat",
    toursDurationPersonal: "1-3 days",
    toursPersonalDescription:
      "• trip to the Alps (location is discussed in person),\n • yoga practice,\n • breathing,\n • meditation,\n • aroma diagnostics with essential oils,\n • selection of personal aroma,\n • hiking with picnic or mountain restaurant,\n • spiritual conversations or practice of vipasana (silence),\n • visiting thermal springs with baths (optional),\n • boat trip on the lake (on some routes)",
    toursPersonalAddInfo:
      "accompanying by car or train, photo/video, assistance with getting ready, solving organizational issues included",
    toursSoulTitle: "Body and Soul",
    toursSoulDuration: "1-2 days",
    toursSoulDescription:
      "• trip to Thermal Springs of Switzerland,\n • yoga therapy at different times (morning/evening),\n • bath art,\n • tea drinking,\n • aroma diagnostics with essential oils,\n • spa and massage (on request),\n • meditation,\n • breathing practices,\n • walking/hiking,\n • soul communication",
    toursSoulAddInfo: "accompanying by car, photo/video",
    toursBaseTitle: "Just Yoga",
    toursBaseDuration: "1 day",
    toursBaseDescription:
      "• trip to a beautiful Swiss natural location,\n • hiking or walking,\n • yoga practice,\n • meditation with the elements (water, earth, fire and water),\n • breathing exercises,\n • pranayama in the mountains,\n • self-massage,\n • relaxation with essential oils,\n • sincere conversations,\n • picnic with tea or mountain restaurant",
    toursBaseAddInfo: "accompanying by car, photo/video optional",
    descriptorCorporate: "Corporate cooperation",
    titleCorporateFirst: "yoga increases ",
    titleCorporateColor: "productivity ",
    titleCorporateSecond:
      "creates a harmonious atmosphere at work. You will enjoy the ",
    titleCorporate: "inspiring results of caring for your employees.",
    corporateFirstText:
      "This is a unique opportunity to unite the team and provide the opportunity to create new ideas while practicing as a team.",
    corporateSecondText:
      "Depending on the general physical level of the group, we will select the appropriate direction of yoga and frequency of practices.",
    corporateThirdText:
      "It is possible to conduct classes online, in your office (Geneva) or in a cozy studio in the center of Geneva.",
    corporateButton: "Find out more",
    descriptorEssentialOils: "Essential oils",
    titleEssentialOilsFirst: "Every ",
    titleEssentialOilsColor: "essential oil ",
    titleEssentialOils:
      "scent is unique, and our sensations from each speak volumes.",
    essentialOilsFirstText:
      "Using essential oils during yoga practices and in everyday life is an ideal addition to maintaining physical and emotional health.",
    essentialOilsSecondText:
      "Essential oils will help stabilize the mind and illuminate the inner path with every breath, strengthen and give flexibility to the body.",
    essentialOilsButton: "Order oils",
    buttonAbout: "More about me",
    aboutREGText:
      "Register and find three author's guides in your personal account!",
    entranceWithGoogle: "Login with Google",
    login: "E-mail",
    password: "Password",
    name: "Name",
    incorrectPasswordErrorLength: "Password must be at least 6 characters",
    incorrectPasswordErrorLetter:
      "Your password should have at least one uppercase character",
    forgotPassportLink: "Forgot password?",
    resetPasswordText: "To reset your password, please enter your email",
    incorrectMailErrorEmpty: "Please provide an email",
    incorrectMailErrorValid: "Please write a valid email",
    errorLoginData: "Please check your entered email and password.",
    resetPasswordSend: "send",
    resetPasswordCancel: "close",
    resetPasswordInstructionsTitle: "password recovery",
    resetPasswordInstructionsContent:
      "Please check your email for password reset instructions",
    // phone: "Phone number",
    section_about: "About me",
    section_prices: "Prices",
    section_course: "Сourse",
    section_practices: "Practices",
    section_meditation: "Meditation",
    section_contact: "Contact",
    section_reviews: "Feedback",
    section_Home: "Home",
    section_booking: "Booking",
    section_cabinet: "Personal account",
    section_Logout: "Logout",
    section_tours: "Personal tours",
    videoPractices:
      "Trial practices will help you develop a strong foundation in both yoga poses and meditation so that you can practice on your own with confidence.",
    menuButtonInvite: "Login",
    invitationToRegistration: "New to Dariya_Yoga?",
    invitationToLogin: "Already in the world of yoga?",
    registration: "Register",
    entrance: "Entrance",
    registrationButton: "Register",
    pressPractices: "Click on the desired body part 🤍",
    inspirationAreaTextOnImg: "Click and get inspired",
    buyEssensialOils: "Order essential oils ✨",
    titleCertificate: "you can click on the certificates :)",
    certificateDescriptor: "Certificates",
    certificateFirst: "hatha yoga teacher",
    certificateSecond: "stretching",
    certificateThird: "pilates during pregnancy",
    certificateFourth: "hatha yoga teacher",
    certificateFifth: "pilates",
    descriptorReviews: "Reviews",
    titleReviewsFirst: "what do ",
    titleReviewsColor: "clients ",
    titleReviews: "say?",
    actionTitle: "Today is the best time!",
    actionText:
      "Practice yoga and join our community of happy people. Start discovering new possibilities, try different classes and levels!",
    footerText:
      "Start your journey to a beautiful body, a harmonious state of inner peace and an endless supply of energy with me!",
    yogapageTextOneFirst:
      "My approach to classes is always special, I compose each lesson from different exercises, based on your wishes.",
    yogapageTextOneSecond:
      "The duration of the lesson is 60 minutes: we start with breathing and tuning, and end with a short meditation. Individual and group sessions are possible (no more than 5 people).",
    yogapageTextThree:
      "Please book in advance so that there is enough space for everyone and the time is convenient. After all, a good attitude is the key to your delightful practice.",
    practice_individual: "Individual",
    practice_group: "Group",
    practice_prenatal: "Prenatal",
    practice_kids: "Kids",
    practice_1:
      "An ideal way to improve your technique and achieve advanced asanas.",
    practice_2: "Regular classes filled with energy, motivation and balance.",
    practice_3:
      "The path to an easy, active pregnancy and supporting your body.",
    practice_4:
      "Teaching children to interact with their bodies, harmonious development.",
    buttonInPracticeCard: "Register",
    descriptorCourse: "Online course",
    titleCourseColor: "An original course ",
    titleCourse:
      "designed to find inner balance, daily self-support and interaction with your body.",
    aboutCourse:
      "The course will help bring body, mind and spirit into a harmonious state. It includes: a full range of interaction on the physical and mental level through asanas, breathing exercises and meditations.",
    courseQuestionFirst: "What level of yoga is required?",
    courseAnswerFirst:
      "The course is suitable for everyone, even people taking their first steps in yoga. Having mastered many asanas and techniques during various practices, you will learn to practice independently at the moment when you need it, but if you have been practicing for a long time, then during the course you will deepen your knowledge, get acquainted with new complexes and practices, and improve your technique. Training is based on a clear and thoughtful methodology. Flow style involves a soft transition between yoga poses, so the process itself is pleasant.",
    courseQuestionSecond: "What will you receive during the course?",
    courseAnswerSecond:
      "Many options for warm-up, exercises, joint exercises, relaxing asanas, strength elements for all muscle groups, which can be varied and included in independent practice. Ideas for connecting and constructing Yoga Flow classes, breathing techniques, as well as a dictionary with names and a guide to chakras.",
    courseQuestionThird: "What is the cost of the course?",
    courseAnswerThird:
      "The cost of the course depends on the chosen tariff. You can view a detailed description of all available packages and select the one that suits you by clicking on the button below.",
    courseQuestionFourth:
      "What does the course consist of, what is the program?",
    courseAnswerFourth:
      "12 lessons (7 yoga classes, 3 classes with breathing exercises and 1 video instruction from me, theoretical materials in your personal account).",
    courseQuestionFifth: "What equipment is needed for classes?",
    courseAnswerFifth:
      "A yoga mat (preferably, if you don’t have one, you can use any carpet, a towel), comfortable clothes, a pillow, a towel (put it on, help yourself), water and essential oils if desired.",
    courseButton: "Сhoose a rate",
    titlePackagesFirst: "This ",
    titlePackagesColor: "course ",
    titlePackagesLast: "is for you if you want:",
    courseAdvantageFirst: "feel light, cheerful and energized",
    courseAdvantageSecond:
      "normalize weight, get rid of swelling and improve skin tone",
    courseAdvantageThird:
      "learn to practice yoga where and when it is convenient",
    courseAdvantageFourth:
      "join a closed club where they improve their health and body",
    courseAdvantageFifth:
      "gain access to thematic yoga lessons with recommendations on different types of breathing, essential oils to improve all aspects of health and well-being.",
    descriptorTariffs: "Rates",
    titleTariffsFirst: "choose a suitable ",
    titleTariffsColor: "program",
    tariffCardTitleFirst: "Independent",
    tariffCardDescriptionFirst:
      "• video parting words from Daria;\n• 10 yoga classes;\n• 4 classes with breathing exercises;\n• theoretical materials: guides on chakras, asanas and essential oils;\n• warm-up, stretching, balance and 2 workshops with group classes.",
    tariffCardPriceFirst: "Cost: 129€",
    tariffCardTitleSecond: "In the community",
    tariffCardDescriptionSecond:
      "• video parting words from Daria;\n• 10 yoga classes;\n• 4 classes with breathing exercises;\n• theoretical materials: guides on chakras, asanas and essential oils;\n• warm-up, stretching, balance and 2 workshops with group classes;\n• unlimited access to online classes via Zoom for a month.",
    tariffCardPriceSecond: "Cost: 289€",
    tariffCardTitleThird: "With a mentor",
    tariffCardDescriptionThird:
      "• video parting words from Daria;\n• 10 yoga classes;\n• 4 classes with breathing exercises;\n• theoretical materials: guides on chakras, asanas and essential oils;\n• warm-up, stretching, balance and 2 workshops with group classes;\n• unlimited access to online classes via Zoom for 3 months;\n• 4 meetings of 30 minutes each with the creation of your personal practice;\n• personal chat with Daria.",
    tariffCardPriceThird: "Cost: 549€",
    tariffButton: "buy",
    videoCourseTitle: "I welcome you to my online course “I ",
    videoCourseTitleColor: "feel ",
    videoCourseTitleLast: "myself”",
    descriptorAddVideo: "Additional lessons",
    titleAddVideoFirst: "a great addition to ",
    titleAddVideoColor: "any ",
    titleAddVideo: "base class",
    videoWarmUp: "warm-up",
    videoStretching: "stretching",
    videoBalance: "balance",
    videoMeditation: "meditation",
    descriptorMainVideo: "Key lessons",
    titleMainVideoFirst: "do the practice that suits you ",
    titleMainVideoColor: "now",
    titleMainVideo: ". What are you like?",
    mainVideoText:
      "I recommend adding essential oil to each lesson, depending on the internal state and topic of the class.",
    practiceConfident: "I'm confident",
    oilsConfident: "Oils: Orange, Bergamot, Lemon, Cinnamon, Cassia, Motivate",
    breathConfident: "Breath: fire, full yogic",
    practiceBalance: "I'm in balance",
    oilsBalance: "Oils: Balance, Lavender",
    breathBalance: "Breath: alternate, full yogic",
    practiceStrong: "I'm strong",
    oilsStrong: "Oils: Orange, Mint, Lemon, Motivate",
    breathStrong: "Breath: fire, alternate",
    practiceEnergetic: "I'm energetic",
    oilsEnergetic: "Oils: Orange, Mint, Lemon, Motivate",
    breathEnergetic: "Breath: full yogic, fire",
    practiceSexy: "I'm sexy",
    oilsSexy: "Oils: Patchouli, Neroli, Rose, Citrus",
    breathSexy: "Breath: full yogic, alternate",
    practiceFree: "I'm free",
    oilsFree: "Oils: Air, Mint, Fir, Serenity",
    breathFree: "Breath: full yogic",
    practiceFlexible: "I'm flexible",
    oilsFlexible: "Oils: Deep blue, Lemon, Mint",
    breathFlexible: "Breathing: all parts of the body",
    practiceTender: "I'm gentle",
    oilsTender: "Oils: Rose, Citrus",
    breathTender: "Breath: full yogic",
    practiceFocused: "I'm in focus",
    oilsFocused: "Oils: Mint, Lemon",
    breathFocused: "Breath: full yogic + light meditation",
    practiceFeel: "I feel myself",
    oilsFeel: "Oils: Balance, Mint",
    breathFeel: "Breath: alternate",
    startBtn: "begin",
    descriptorBreathVideo: "Breathing Lessons",
    titleBreathVideoFirst: "Complement your practice with yoga ",
    titleBreathVideoColor: "breathing",
    breathVideoFirst: "Full yogic",
    breathVideoSecond: "Fire (Kapalbhati)",
    breathVideoThird: "Alternating (Nadi Shodhana)",
    breathVideoFourth: "Happiness",
    descriptorWorkshopVideo: "Workshop",
    titleWorkshopVideo:
      "group practices will help you diversify your practices",
    videoEnergyWorkshop: "yoga practice energy",
    videoCalmWorkshop: "yoga practice calm and balance",
    meditionHeaderTitle: "breathing\nbalance\nharmony",
    meditatiomMainTitle: "your time",
    meditationSubTitle: "for meditation",
    buttonBookNow: "Book Now",
    bookDate: "Book the date and time that works for you",
    chooseTrainingType: "choose a convenient training format",
    bookingSummary: "Booking summary",
    dateBooking: "Your date",
    buttonBooking: "Confirm",
    member: "Already a member",
    loginBooking: "Login",
    personalcabinetpageData: "Your data",
    goToCourseBtn: "Go to course",
    personalcabinetpagePrev: "Your previous classes",
    personalcabinetpageCurr: "Your future classes",
    emptyBooking: "No vacant tables",
    guidesText:
      "These guides will help you learn more about yoga and implement knowledge into your practices.",
    essentialOilsGuide: "essential oils guide",
    dictionaryGuide: "guide to yoga asanas",
    chackrasGuide: "chakra guide",
    feedbackpage:
      "Share your experience - tell us about your impressions after the practice, course, meditation.",
    labelComment: "Place for your review",
    prevComment: "previous",
    nextComment: "next",
    userCommentChat: "Let's chat?",
    userCommentName: "Your name",
    userCommentEmail: "Your email",
    userCommentSubject: "Subject",
    userCommentMessage: "Your message (optional)",
    userCommentBtn: "Submit",
    promotionTitle: "All inclusive 7-day yoga retreat in the Maldives",
    promotionSubTitle: "25.01.2025 - 01.02.2025, 8 days/7 nights 1950€ + flight",
    promotionButton: "learn more",
  },
  [LOCALES.RUSSIAN]: {
    descriptorAdvantages: "Преимущества",
    advantagesFirst: "Особый подход",
    advantagesSecond: "Разные типы практик",
    advantagesThird: "Поддержка в чате йога-сообщества",
    advantagesFourth: "Возможность заниматься онлайн или в студии",
    advantagesFifth: "Проведение выездных ретритов",
    coverTitle: "dariya yoga",
    coverText:
      "Персональные и групповые практики, классы онлайн и офлайн, йога для беременных, ретриты и йога-девичники по всему миру.",
    introductionTitle: "Почему я?",
    introductionExperience: "4+ года практик",
    introductionPersonalApproach: "индивидуальный подход к каждому клиенту",
    introductionCommunity: "большое йога-комьюнити счастливых людей",
    aboutMeFirst:
      "Меня зовут Дарья, мне 31 год. Сейчас я живу во Франции, 3 года я провела в Швейцарии, постоянно заряжаюсь энергией Альп, вдохновляюсь природой, стихиями и красотой вокруг, а ещё - я мама двоих детей!",
    aboutMeSecond:
      "Практикуя разные стили йоги, родился мой уникальный стиль, в котором Вы найдёте более динамичные упражнения, комплексы на спину, расслабление, растяжку, а также традиционные асаны на баланс и дыхательные практики.",
    descriptorPrices: "Цены и абонементы",
    titlePricesFirst:
      "Вы можете приобрести разовое посещение или Абонемент по более ",
    titlePricesColor: "выгодной цене ",
    titlePrices: "на групповые практики online.",
    pricesPersonalLesson: "Персональный класс",
    pricesPersonalLessonDuration: "60 минут",
    pricesPersonalLessonPrice: "100€",
    pricesPersonalLessonZoom: "Персональный класс в zoom",
    pricesPersonalLessonZoomDuration: "60 минут",
    pricesPersonalLessonZoomPrice: "70€",
    pricesPrenatal: "Персональный класс Prenatal",
    pricesPrenatalDuration: "60 минут",
    pricesPrenatalPrice: "100€",
    pricesPrenatalZoom: "Персональный класс Prenatal в zoom",
    pricesPrenatalZoomDuration: "60 минут",
    pricesPrenatalZoomPrice: "70€",
    pricesGroupPractice: "Групповой класс",
    pricesGroupPracticeDuration: "60 минут",
    pricesGroupPracticePrice: "40€",
    pricesGroupPracticeZoom: "Групповой класс в zoom",
    pricesGroupPracticeZoomDuration: "60 минут",
    pricesGroupPracticeZoomPrice: "25€",
    subscription4practices: "Абонемент на 4 класса",
    subscription4practicesPrice: "80€",
    subscription4practicesDuration: "60 минут",
    subscription8practices: "Абонемент на 8 классов",
    subscription8practicesPrice: "150€",
    subscription8practicesDuration: "60 минут",
    subscription12practices: "Абонемент на 12 классов",
    subscription12practicesPrice: "180€",
    subscription12practicesDuration: "60 минут",
    subscription16practices: "Абонемент на 16 классов",
    subscription16practicesPrice: "200€",
    subscription16practicesDuration: "60 минут",
    buttonInPriceCard: "Оплатить",
    descriptorAbout: "О преподавателе",
    titleAboutFirst: "Меня зовут Дарья, мой путь в йоге начался ",
    titleAboutColor: "в Индии, ",
    titleAbout:
      "где изучая различные направления, родился уникальный стиль,\nв котором Вы найдёте то, что вам близко.",
    aboutFirstText:
      "Проживая во Франции, я постоянно заряжаюсь энергией Альп, вдохновляюсь природой, стихиями и красотой вокруг, благодаря чему создаю уникальные ретриты, провожу воркшопы и мотивирую людей заниматься йогой, а ещё - я мама двоих детей!",
    aboutSecondText:
      "В моих практиках Вы найдёте динамичные упражнения, комплексы на спину, расслабление, растяжку, а также традиционные асаны на баланс и дыхательные практики.",
    descriptorTours: "Выездные туры и ретриты",
    titleToursFirst: "Вы освоите дыхательные практики и медитации, узнаете о ",
    titleToursColor1: "йогическом ",
    titleToursColor2: "образе жизни, ",
    titleTours: "внедрите новые здоровые привычки, отстроите асаны.",
    toursPersonalTitle: "Персональный ретрит",
    toursDurationPersonal: "1-3 дня",
    toursPersonalDescription:
      "• поездка в Альпы (локация обсуждается лично),\n • йога-практика,\n • дыхание,\n • медитация,\n • аромадиагностика с эфирными маслами,\n • подбор персонального аромата,\n • хайкинг с пикником или горным рестораном,\n • душевные разговоры или практика випасаны (тишины),\n • посещение термальных источников с банями (по желанию),\n • прогулка на корабле по озеру (на некоторых маршрутах)",
    toursPersonalAddInfo:
      "сопровождение на автомобиле или на поезде, фото/видео съёмка, помощь в сборах, решение организационных вопросов включено",
    toursSoulTitle: "Тело и Душа",
    toursSoulDuration: "1-2 дня",
    toursSoulDescription:
      "• поездка в Термальные источники Швейцарии,\n • йога-терапия в разное время (утро/вечер),\n • банное искусство,\n • чаепитие,\n • аромадиагностика с эфирными маслами,\n • спа и массаж (по запросу),\n • медитация,\n • дыхательные практики,\n • прогулка/хайкинг,\n • душевное общение",
    toursSoulAddInfo: "сопровождение на автомобиле, фото/видео съёмка",
    toursBaseTitle: "Just Йога",
    toursBaseDuration: "1 день",
    toursBaseDescription:
      "• поездка к красивой Швейцарской природной локации,\n • хайкинг или прогулка,\n • йога-практика,\n • медитация со стихиями (вода, земля, огонь и вода),\n • дыхательные упражения,\n • пранаяма в горах,\n • самомассаж, расслабление с эфирными маслами,\n • душевные разговоры,\n • пикник c чаепитием или горный ресторан",
    toursBaseAddInfo:
      "сопровождение на автомобиле или поезде, фото и видео по желанию",
    descriptorCorporate: "Корпоративное сотрудничество",
    titleCorporateFirst: "йога повышает ",
    titleCorporateColor: "продуктивность ",
    titleCorporateSecond:
      "создаёт гармоничную атмосферу на работе. Вы будете наслаждаться ",
    titleCorporate: "вдохновляющим результатом заботы о своих сотрудниках.",
    corporateFirstText:
      "Это уникальная возможность сплотить коллектив и дать возможность создавать новые идеи, практикуя в команде.",
    corporateSecondText:
      "В зависимости от общего физического уровня группы, подберём подходящее направление йоги и частоту практик.",
    corporateThirdText:
      "Возможно проводить занятия онлайн, в Вашем офисе (Женева) или в уютной студии в центре Женевы.",
    corporateButton: "Узнать подробнее",
    descriptorEssentialOils: "Эфирные масла",
    titleEssentialOilsFirst: "Каждый аромат ",
    titleEssentialOilsColor: "эфирного масла ",
    titleEssentialOils:
      "уникален, а наши ощущения от каждого говорят о многом.",
    essentialOilsFirstText:
      "Использование эфирных масел во время йога-практик и в повседневной жизни - идеальное дополнение к поддержанию физического и эмоционального здоровья.",
    essentialOilsSecondText:
      "Эфирные масла помогут стабилизировать сознание и осветить внутренний путь при каждом вдохе, укрепить и придать гибкости телу.",
    essentialOilsButton: "Заказать масла",
    entranceWithGoogle: "Вход через Google",
    login: "электронная почта",
    password: "пароль",
    name: "Имя",
    incorrectPasswordErrorLength: "Пароль должен содержать не менее 6 символов",
    incorrectPasswordErrorLetter:
      "Ваш пароль должен содержать хотя бы один символ верхнего регистра",
    forgotPassportLink: "Забыли пароль?",
    resetPasswordText:
      "Для сброса пароля, пожалуйста, введите свою электронную почту",
    incorrectMailErrorEmpty: "Пожалуйста, укажите адрес электронной почты",
    incorrectMailErrorValid:
      "Пожалуйста, проверьте корректность электронной почты",
    errorLoginData: "Пожалуйста, проверьте введённые логин и пароль",
    resetPasswordSend: "отправить",
    resetPasswordCancel: "закрыть",
    resetPasswordInstructionsTitle: "восстаноление пароля",
    resetPasswordInstructionsContent:
      "Пожалуйста, проверьте свою электронную почту, чтобы получить инструкции по сбросу пароля.",
    buttonAbout: "Больше обо мне",
    aboutREGText:
      "Зарегистрируйся и найди три авторских гайда в своём личном кабинете!",
    // phone: "Номер телефона",
    section_about: "Обо мне",
    section_prices: "Цены",
    section_course: "Курс",
    section_practices: "Практики",
    section_tours: "Туры",
    section_meditation: "Медитации",
    section_contact: "Связаться",
    section_reviews: "Отзывы",
    section_Home: "Домой",
    section_booking: "Бронирование",
    section_cabinet: "Личный кабинет",
    section_Logout: "Выход",
    videoPractices:
      "Пробные практики помогут вам развить прочную основу как асан йоги, так и медитации, чтобы вы могли с уверенностью практиковать самостоятельно.",
    menuButtonInvite: "Авторизоваться",
    invitationToRegistration: "Впервые в Dariya_Yoga?",
    invitationToLogin: "Уже в мире йоги?",
    registration: "Зарегистрироваться",
    entrance: "Войти",
    registrationButton: "Зарегистрироваться",
    pressPractices: "Нажми на нужную часть тела 🤍",
    inspirationAreaTextOnImg: "Нажми и вдохновись",
    buyEssensialOils: "Заказать эфирные масла ✨",
    titleCertificate: "на сертификаты можно кликать:)",
    certificateDescriptor: "Сертификаты",
    certificateFirst: "hatha yoga teacher",
    certificateSecond: "stretching",
    certificateThird: "pilates during pregnancy",
    certificateFourth: "hatha yoga teacher",
    certificateFifth: "pilates",
    descriptorReviews: "Отзывы",
    titleReviewsFirst: "что ",
    titleReviewsColor: "говорят ",
    titleReviews: "клиенты?",
    actionTitle: "Сегодня - самое лучшее время!",
    actionText:
      "Практикуй йогу и присоединяйся к нашему комьюнити счастливых людей. Начни открывать новые возможности, попробуй различные классы и уровни!",
    footerText:
      "Начните свой путь к красивому телу, гармоничному состоянию внутреннего мира и нескончаемому запасу энергии вместе со мной!",
    yogapageTextOneFirst: `Мой подход к занятиям всегда особенный, каждый урок я компоную из разных упражнений, основываясь на ваших пожеланиях.`,
    yogapageTextOneSecond:
      "Длительность урока составляет 60 минут: начинаем дыханием и настройкой, а завершаем короткой медитацией. Возможна индивидуальная и групповая сессия (не более 5 человек).",
    yogapageTextThree:
      "Записываться прошу заранее, чтобы всем хватило мест и время было удобным. Ведь хороший настрой – залог Вашей восхитительной практики.",
    practice_individual: "Индивидуальная",
    practice_group: "Групповая",
    practice_prenatal: "Prenatal",
    practice_kids: "Kids",
    practice_1:
      "Идеальный способ улучшить Вашу технику, достигнуть продвинутых асан.",
    practice_2:
      "Регулярные классы, наполненные энергией, мотивацией и балансом.",
    practice_3:
      "Путь к лёгкой, активной беременности и поддержки своего тела. ",
    practice_4:
      "Обучение детей взаимодействовать со своим телом, гармоничное развитие.",
    buttonInPracticeCard: "Записаться",
    descriptorCourse: "Онлайн-курс",
    titleCourseColor: "Авторский курс, ",
    titleCourse:
      "разработанный для поиска внутреннего баланса, ежедневной поддержки себя и взаимодействия со своим телом.",
    aboutCourse:
      "Курс поможет привести в гармоничное состояние тело, разум и дух. В него включён: полный комплекс взаимодействия на физическом и ментальном уровне через выполнение асан, дыхательных упражнений и медитаций.",
    courseQuestionFirst: "Какой уровень в йоге необходим?",
    courseAnswerFirst:
      "Курс подойдет всем, даже людям, делающим первые шаги в йоге. Освоив множество асан и техник во время различных практик, Вы научитесь практиковать самостоятельно в тот момент, когда Вам это потребуется, если же Вы давно практикуете, то на курсе Вы углубите свои знания, познакомитесь с новыми комплексами и практиками, усовершенствуете свою технику. Обучение строится на четкой и продуманной методологии. Стиль Flow подразумевает мягкий переход между позами йоги, поэтому приятен самим процессом.",
    courseQuestionSecond: "Что Вы получите в процессе прохождения курса?",
    courseAnswerSecond:
      "Множество вариантов разминки, зарядки, суставных упражнений, расслабляющих асан, силовых элементов на все группы мышц, которые можно будет варьировать и включать в самостоятельную практику. Идеи связок и построения Yoga Flow классов, дыхательные техники, а также словарь с названиями и гайд по чакрам. ",
    courseQuestionThird: "Какая стоимость курса?",
    courseAnswerThird:
      "Стоимость курса зависит от выбранного тарифа. Ознакомиться с подробным описанием всех доступных пакетов и выбрать подходящий можно кликнув на кнопку ниже.",
    courseQuestionFourth: "Из чего состоит курс, какая программа?",
    courseAnswerFourth:
      "12 уроков (7 йога-классов, 3 класса с дыхательными упражнениями и 1 видео-напутствие от меня, теоретические материалы в личном кабинете).",
    courseQuestionFifth: "Какой инвентарь нужен для занятий?",
    courseAnswerFifth:
      "Коврик для йоги (желательно, при отсутствии, можно любой ковер, полотенце), удобная одежда, подушечка, полотенце (подложить, помочь себе) вода и эфирные масла по желанию.",
    courseButton: "Выбрать тариф",
    titlePackagesFirst: "Этот ",
    titlePackagesColor: "курс ",
    titlePackagesLast: "для Вас, если Вы хотите:",
    courseAdvantageFirst: "чувствовать лёгкость, бодрость и прилив сил",
    courseAdvantageSecond:
      "нормализовать вес, избавиться от отёков и улучшить тонус кожи",
    courseAdvantageThird: "научиться практиковать йогу где и когда удобно",
    courseAdvantageFourth:
      "вступить в закрытый клуб, где совершенствуют своё здоровье и тело",
    courseAdvantageFifth:
      "получить доступ к тематическим урокам йоги с рекомендациями по различным видам дыхания, эфирным маслам, позволяющим улучшить все аспекты здоровья и самочувствия.",
    descriptorTariffs: "Тарифы",
    titleTariffsFirst: "выбрать подходящую ",
    titleTariffsColor: "программу",
    tariffCardTitleFirst: "Самостоятельная",
    tariffCardDescriptionFirst:
      "• видео-напутствие от Дарьи;\n• 10 йога-классов;\n• 4 класса с дыхательными упражнениями;\n• теоретические материалы: гайды по чакрам, асанам и эфирным маслам;\n• разминка, растяжка, баланс и 2 воркшопа с групповых классов.",
    tariffCardPriceFirst: "Стоимость: 129€",
    tariffCardTitleSecond: "В сообществе",
    tariffCardDescriptionSecond:
      "• видео-напутствие от Дарьи;\n• 10 йога-классов;\n• 4 класса с дыхательными упражнениями;\n• теоретические материалы: гайды по чакрам, асанам и эфирным маслам;\n• разминка, растяжка, баланс и 2 воркшопа с групповых классов;\n• безлимитное посещение онлайн-классов в zoom в течение месяца.",
    tariffCardPriceSecond: "Стоимость: 289€",
    tariffCardTitleThird: "С наставником",
    tariffCardDescriptionThird:
      "• видео-напутствие от Дарьи;\n• 10 йога-классов;\n• 4 класса с дыхательными упражнениями;\n• теоретические материалы: гайды по чакрам, асанам и эфирным маслам;\n• разминка, растяжка, баланс и 2 воркшопа с групповых классов;\n• безлимитное посещение онлайн-классов в zoom в течение 3 месяцев;\n• 4 встречи по 30 минут с созданием своей личной практики;\n• личный чат с Дарьей.",
    tariffCardPriceThird: "Стоимость: 549€",
    tariffButton: "купить",
    videoCourseTitle: "приветствую Вас на моём онлайн-курсе «Я ",
    videoCourseTitleColor: "чувствую ",
    videoCourseTitleLast: "себя»",
    descriptorAddVideo: "Дополнительные уроки",
    titleAddVideoFirst: "Прекрасное дополнение ",
    titleAddVideoColor: "любого ",
    titleAddVideo: "базового класса",
    videoWarmUp: "разминка",
    videoStretching: "растяжка",
    videoBalance: "баланс",
    videoMeditation: "медитация",
    descriptorMainVideo: "Основные уроки",
    titleMainVideoFirst: "выполните практику, подходящую вам ",
    titleMainVideoColor: "сейчас",
    titleMainVideo: ". Какая Вы?",
    mainVideoText:
      "Рекомендую добавлять к каждому уроку эфирное масло, в зависимости от внутреннего состояния и темы класса.",
    practiceConfident: "Я уверенная",
    oilsConfident: "Масла: Апельсин, Бергамот, Лимон, Корица, Кассия, Motivate",
    breathConfident: "Дыхание: огня, полное йоговское",
    practiceBalance: "Я в балансе",
    oilsBalance: "Масла: Balance, Лаванда",
    breathBalance: "Дыхание: попеременное, полное йоговское",
    practiceStrong: "Я сильная",
    oilsStrong: "Масла: Апельсин, Мята, Лимон, Motivate",
    breathStrong: "Дыхание: огня, попеременное",
    practiceEnergetic: "Я энергичная",
    oilsEnergetic: "Масла: Апельсин, Мята, Лимон, Motivate",
    breathEnergetic: "Дыхание: полное йоговское, огня",
    practiceSexy: "Я сексуальная",
    oilsSexy: "Масла: Пачули, Нероли, Роза, Цитрусовые",
    breathSexy: "Дыхание: полное йоговское, попеременное",
    practiceFree: "Я свободная",
    oilsFree: "Масла: Air, Мята, Пихта, Serenity",
    breathFree: "Дыхание: полное йоговское",
    practiceFlexible: "Я гибкая",
    oilsFlexible: "Масла: Deep blue, Лимон, Мята",
    breathFlexible: "Дыхание: всеми частями тела",
    practiceTender: "Я нежная",
    oilsTender: "Масла: Роза, Цитрусовые",
    breathTender: "Дыхание: полное йоговское",
    practiceFocused: "Я в фокусе",
    oilsFocused: "Масла: мята, лимон",
    breathFocused: "Дыхание: полное йоговское + легкая медитация",
    practiceFeel: "Я чувствую Себя",
    oilsFeel: "Масла: Balance, мята",
    breathFeel: "Дыхание: попеременное",
    startBtn: "начать",
    descriptorBreathVideo: "Уроки по дыханию",
    titleBreathVideoFirst: "Дополните свою практику йоговским ",
    titleBreathVideoColor: "дыханием",
    breathVideoFirst: "Полное йоговское",
    breathVideoSecond: "Огня (Капалабхати)",
    breathVideoThird: "Попеременное (Нади Шодхана) ",
    breathVideoFourth: "Счастья",
    descriptorWorkshopVideo: "Воркшопы",
    titleWorkshopVideo:
      "групповые практики помогут Вам разнообразить Ваши практики",
    videoEnergyWorkshop: "йога-практика энергия",
    videoCalmWorkshop: "йога-практика спокойствие и баланс",
    meditionHeaderTitle: "дыхание\nбаланс\nгармония",
    meditatiomMainTitle: "ваше время",
    meditationSubTitle: "для медитаций ",
    buttonBookNow: "Забронировать",
    bookDate: "Забронируйте удобное для Вас время и дату",
    chooseTrainingType: "выберите удобный формат тренировки",
    bookingSummary: "Данные по Вашему бронированию",
    dateBooking: "Ваша дата",
    buttonBooking: "Подтверждаю",
    member: "Уже зарегистрированы",
    loginBooking: "Авторизуйтесь",
    personalcabinetpageData: "Ваши данные",
    goToCourseBtn: "Перейти к курсу",
    personalcabinetpagePrev: "Предыдущие занятия",
    personalcabinetpageCurr: "Планируемые занятия",
    emptyBooking: "Свободных мест нет",
    guidesText:
      "Данные гайды помогут Вам узнать больше о йоге и внедрить знания в свои практики.",
    essentialOilsGuide: "гайд по эфирным маслам",
    dictionaryGuide: "гайд по асанам йоги",
    chackrasGuide: "гайд по чакрам",
    feedbackpage:
      "Поделитесь опытом — расскажите о Ваших впечатлениях после практики, курса, медитации.",
    labelComment: "Место для Вашего отзыва",
    prevComment: "предыдущий",
    nextComment: "следующий",
    userCommentChat: "Давайте общаться?",
    userCommentName: "Ваше имя",
    userCommentEmail: "Ваша электронная почта",
    userCommentSubject: "Тема",
    userCommentMessage: "Ваше сообщение (опционально)",
    userCommentBtn: "Отправить",
    promotionTitle: "All inclusive йога-ретрит на Мальдивах",
    promotionSubTitle: "25.01.2025 - 01.02.2025, 8 дней/7 ночей 1950€ + перелет",
    promotionButton: "узнать больше",
  },
  [LOCALES.FRENCH]: {
    descriptorAdvantages: "Avantages",
    advantagesFirst: "Approche spéciale",
    advantagesSecond: "Différents types de pratiques",
    advantagesThird: "Assistance par chat de la communauté de yoga",
    advantagesFourth: "Possibilité d'étudier en ligne ou en studio",
    advantagesFifth: "Organiser des retraites",
    coverTitle: "dariya yoga",
    coverText:
      "Pratiques personnelles et en groupe, cours en ligne et hors ligne, yoga pour femmes enceintes, retraites et enterrements de vie de jeune fille de yoga dans le monde entier.",
    introductionTitle: "Pourquoi moi?",
    introductionExperience: "4+ années de pratique",
    introductionPersonalApproach: "Approche individuelle de chaque client",
    introductionCommunity:
      "Une grande communauté de yoga composée de gens heureux",
    aboutMeFirst:
      "Je m'appelle Daria, j'ai 31 ans. Maintenant je vis en France, j'ai passé 3 ans en Suisse, je suis constamment chargée de l'énergie des Alpes, inspirée par la nature, les éléments et la beauté qui m'entoure, et je suis aussi maman de deux enfants!",
    aboutMeSecond:
      "Pratiquant différents styles de yoga, mon style unique est né, dans lequel vous retrouverez des exercices plus dynamiques, des exercices du dos, de la relaxation, des étirements, ainsi que des asanas d'équilibre et des pratiques de respiration traditionnelles.",
    descriptorPrices: "Tarifs et abonnements",
    titlePricesFirst:
      "Vous pouvez acheter une visite unique ou un abonnement à un ",
    titlePricesColor: "meilleur prix ",
    titlePrices: "pour les pratiques de groupe en ligne.",
    pricesPersonalLesson: "Cours personnel",
    pricesPersonalLessonDuration: "60 minutes",
    pricesPersonalLessonPrice: "100€",
    pricesPersonalLessonZoom: "Cours personnel en zoom",
    pricesPersonalLessonZoomDuration: "60 minutes",
    pricesPersonalLessonZoomPrice: "70€",
    pricesPrenatal: "Cours prénatal personnel",
    pricesPrenatalDuration: "60 minutes",
    pricesPrenatalPrice: "100€",
    pricesPrenatalZoom: "Cours prénatal personnel en zoom",
    pricesPrenatalZoomDuration: "60 minutes",
    pricesPrenatalZoomPrice: "70€",
    pricesGroupPractice: "Pratique dans le groupe",
    pricesGroupPracticeDuration: "60 minutes",
    pricesGroupPracticePrice: "40€",
    pricesGroupPracticeZoom: "Pratique dans le groupe sur zoom",
    pricesGroupPracticeZoomDuration: "60 minutes",
    pricesGroupPracticeZoomPrice: "25€",
    subscription4practices: "abonnement pour 4 cours",
    subscription4practicesPrice: "80€",
    subscription4practicesDuration: "60 minutes",
    subscription8practices: "abonnement pour 8 cours",
    subscription8practicesPrice: "150€",
    subscription8practicesDuration: "60 minutes",
    subscription12practices: "abonnement pour 12 cours",
    subscription12practicesPrice: "180€",
    subscription12practicesDuration: "60 minutes",
    subscription16practices: "abonnement pour 16 cours",
    subscription16practicesPrice: "200€",
    subscription16practicesDuration: "60 minutes",
    buttonInPriceCard: "Payer",
    descriptorAbout: "À propos du professeur",
    titleAboutFirst:
      "Je m'appelle Daria, mon parcours dans le yoga a commencé ",
    titleAboutColor: "en Inde, ",
    titleAbout:
      "où, en étudiant diverses directions, est né un style unique dans lequel vous trouverez quelque chose qui vous est proche.",
    aboutFirstText:
      "Vivant en France, je suis constamment chargée de l'énergie des Alpes, inspirée par la nature, les éléments et la beauté qui m'entoure, grâce auxquelles je crée des retraites uniques, anime des ateliers et motive les gens à faire du yoga, et je suis aussi mère de deux enfants !",
    aboutSecondText:
      "Dans mes pratiques, vous trouverez des exercices dynamiques, des exercices du dos, de la relaxation, des étirements, ainsi que des asanas d'équilibre et des pratiques de respiration traditionnelles.",
    descriptorTours: "Visites en plein air et retraites",
    titleToursFirst:
      "Vous maîtriserez les pratiques de respiration et de méditation, découvrirez ",
    titleToursColor1: "le mode ",
    titleToursColor2: "de vie yogique, ",
    titleTours:
      "introduire de nouvelles habitudes saines, construire des asanas.",
    toursPersonalTitle: "Retraite personnelle",
    toursDurationPersonal: "1-3 jours",
    toursPersonalDescription:
      "• voyage dans les Alpes (le lieu est à discuter en personne),\n • pratique du yoga,\n • respiration,\n • méditation,\n • diagnostic aromatique aux huiles essentielles,\n • sélection d'un parfum personnel,\n • randonnée avec pique-nique ou restaurant de montagne,\n • conversations intimes ou pratique du vipasana (silence),\n • visite sources thermales avec bains (en option),\n • promenade en bateau sur le lac (sur certains itinéraires)",
    toursPersonalAddInfo:
      "accompagnement en voiture ou en train, prise de vue photo/vidéo, aide à la préparation, résolution de problèmes d'organisation inclus",
    toursSoulTitle: "Corps et âme",
    toursSoulDuration: "1-2 jours",
    toursSoulDescription:
      "• voyage aux sources thermales de Suisse,\n • yoga thérapie à différents moments (matin/soir),\n • art du bain,\n • consommation de thé,\n • diagnostic aromatique aux huiles essentielles,\n • spa et massage (sur demande),\n • méditation,\n • pratiques respiratoires,\n • marche/randonnée,\n • communication spirituelle",
    toursSoulAddInfo: "accompagnement en voiture, photo/vidéo",
    toursBaseTitle: "Juste du Yoga",
    toursBaseDuration: "1 jour",
    toursBaseDescription:
      "• voyage dans un magnifique site naturel suisse,\n • randonnée ou marche,\n • pratique du yoga,\n • méditation avec les éléments (eau, terre, feu et eau),\n • exercices de respiration,\n • pranayama en montagne,\n • auto-massage,\n • relaxation aux huiles essentielles,\n • conversations émouvantes,\n • pique-nique avec thé ou restaurant d'altitude",
    toursBaseAddInfo:
      "accompagnement en voiture ou en train, photos et vidéos sur demande",
    descriptorCorporate: "Coopération d'entreprise",
    titleCorporateFirst: "le yoga augmente ",
    titleCorporateColor: "la productivité ",
    titleCorporateSecond:
      "crée une atmosphère harmonieuse au travail. Vous apprécierez les résultats ",
    titleCorporate: "inspirants du soin apporté à vos employés.",
    corporateFirstText:
      "Il s’agit d’une occasion unique de fédérer l’équipe et de créer de nouvelles idées tout en pratiquant en équipe.",
    corporateSecondText:
      "En fonction du niveau physique général du groupe, nous sélectionnerons la direction du yoga et la fréquence des pratiques appropriées.",
    corporateThirdText:
      "Il est possible de donner des cours en ligne, dans votre bureau (Genève) ou dans un studio cosy au centre de Genève.",
    corporateButton: "En savoir plus",
    descriptorEssentialOils: "Huiles essentielles",
    titleEssentialOilsFirst: "Chaque parfum ",
    titleEssentialOilsColor: "d’huile essentielle ",
    titleEssentialOils:
      "est unique et nos sensations de chacune en disent long.",
    essentialOilsFirstText:
      "L’utilisation d’huiles essentielles lors des pratiques de yoga et dans la vie quotidienne est un complément idéal au maintien de la santé physique et émotionnelle.",
    essentialOilsSecondText:
      "Les huiles essentielles aideront à stabiliser l’esprit et à éclairer le chemin intérieur à chaque respiration, à renforcer et à donner de la souplesse au corps.",
    essentialOilsButton: "Commander des huiles",
    entranceWithGoogle: "connectez-vous en utilisant Google",
    login: "E-mail",
    password: "Le mot de passe",
    incorrectPasswordErrorLength:
      "Le mot de passe doit comporter au moins 6 caractères",
    incorrectPasswordErrorLetter:
      "Votre mot de passe doit contenir au moins un caractère majuscule",
    forgotPassportLink: "Mot de passe oublié?",
    resetPasswordText:
      "Pour réinitialiser votre mot de passe, veuillez entrer votre email",
    incorrectMailErrorEmpty: "Veuillez entrer votre adresse e-mail",
    incorrectMailErrorValid: "Veuillez vérifier que votre email est correct",
    errorLoginData:
      "Veuillez vérifier votre nom d'utilisateur et votre mot de passe saisis.",
    resetPasswordSend: "envoyer",
    resetPasswordCancel: "fermer",
    resetPasswordInstructionsTitle: "récupération de mot de passe",
    resetPasswordInstructionsContent:
      "Veuillez vérifier votre courrier électronique pour les instructions de réinitialisation du mot de passe.",
    buttonAbout: "Plus à propos de moi",
    aboutREGText:
      "Inscrivez-vous et retrouvez trois guides d'auteur dans votre compte personnel!",
    name: "Nom",
    // phone: "Numéro de téléphone",
    section_about: "Sur moi",
    section_prices: "Des prix",
    section_course: "Cours",
    section_practices: "Les pratiques",
    section_meditation: "Méditation",
    section_contact: "Contacter",
    section_reviews: "Commentaires",
    section_Home: "Domicile",
    section_booking: "Réservation",
    section_cabinet: "Compte personnel",
    section_Logout: "Se déconnecter",
    section_tours: "Visites personnelles",
    videoPractices:
      "Les pratiques d'essai vous aideront à développer une base solide dans les poses de yoga et la méditation afin que vous puissiez pratiquer seul en toute confiance.",
    menuButtonInvite: "Connexion",
    invitationToRegistration: "Nouveau sur Dariya_Yoga ?",
    invitationToLogin: "Déjà dans le monde du yoga?",
    registration: "Registre",
    entrance: "Entrée",
    registrationButton: "S'inscrire",
    pressPractices: `Cliquez sur la partie du corps souhaitée 🤍`,
    inspirationAreaTextOnImg: `Cliquez et inspirez-vous`,
    buyEssensialOils: "Commander des huiles essentielles ✨",
    titleCertificate: "vous pouvez cliquer sur les certificats :)",
    certificateDescriptor: "Certificats",
    certificateFirst: "hatha yoga teacher",
    certificateSecond: "stretching",
    certificateThird: "pilates during pregnancy",
    certificateFourth: "hatha yoga teacher",
    certificateFifth: "pilates",
    descriptorReviews: "Commentaires",
    titleReviewsFirst: "que ",
    titleReviewsColor: "disent ",
    titleReviews: "les clients?",
    actionTitle: "Aujourd'hui, c'est le meilleur moment !",
    actionText:
      "Pratiquez le yoga et rejoignez notre communauté de personnes heureuses. Commencez à découvrir de nouvelles possibilités, essayez différentes classes et niveaux !",
    footerText:
      "Commencez votre voyage vers un corps magnifique, un état harmonieux de paix intérieure et une réserve d’énergie inépuisable avec moi!",
    yogapageTextOneFirst: `Mon approche des cours est toujours particulière, je compose chaque cours à partir d'exercices différents, en fonction de vos souhaits.`,
    yogapageTextOneSecond:
      "La durée de la leçon est de 60 minutes: nous commençons par la respiration et l'accord, et terminons par une courte méditation. Des séances individuelles et collectives sont possibles (pas plus de 5 personnes).",
    yogapageTextThree: `Veuillez réserver à l'avance afin qu'il y ait suffisamment d'espace pour tout le monde et que l'heure soit convenable. Après tout, une bonne attitude est la clé de votre délicieuse pratique.`,
    practice_individual: "Individuel",
    practice_group: "Pratique de groupe",
    practice_prenatal: "Prenatal",
    practice_kids: "Kids",
    practice_1: `Un moyen idéal pour améliorer votre technique et réaliser des asanas avancés.`,
    practice_2: `Cours réguliers remplis d'énergie, de motivation et d'équilibre.`,
    practice_3:
      "Le chemin vers une grossesse facile et active tout en soutenant votre corps.",
    practice_4:
      "Apprendre aux enfants à interagir avec leur corps, un développement harmonieux.",
    buttonInPracticeCard: "S'inscrire",
    descriptorCourse: "Cours en ligne",
    titleCourseColor: "Un cours original ",
    titleCourse: `conçu pour retrouver l'équilibre intérieur, l'autonomie au quotidien et l'interaction avec son corps.`,
    aboutCourse:
      "Le cours aidera à amener le corps, l'esprit et l'esprit dans un état harmonieux. Il comprend : une gamme complète d'interactions sur le plan physique et mental à travers des asanas, des exercices de respiration et des méditations.",
    courseQuestionFirst: "Quel niveau de yoga est requis ?",
    courseAnswerFirst:
      "Le cours s’adresse à tout le monde, même aux personnes faisant leurs premiers pas dans le yoga. Après avoir maîtrisé de nombreux asanas et techniques au cours de diverses pratiques, vous apprendrez à pratiquer de manière autonome au moment où vous en avez besoin, mais si vous pratiquez depuis longtemps, alors au cours du cours vous approfondirez vos connaissances, vous familiariserez avec de nouveaux complexes et pratiques, et améliorer votre technique. La formation repose sur une méthodologie claire et réfléchie. Le style Flow implique une transition douce entre les poses de yoga, de sorte que le processus lui-même est agréable.",
    courseQuestionSecond: "Que recevrez-vous pendant le cours ?",
    courseAnswerSecond:
      "De nombreuses options d'échauffement, d'exercices, d'exercices articulaires, d'asanas relaxants, d'éléments de force pour tous les groupes musculaires, qui peuvent être variés et inclus dans une pratique autonome. Des idées pour connecter et construire des cours de Yoga Flow, des techniques de respiration, ainsi qu'un dictionnaire avec des noms et un guide des chakras.",
    courseQuestionThird: "Quel est le coût du cours?",
    courseAnswerThird:
      "Le coût du cours dépend du tarif choisi. Vous pouvez consulter une description détaillée de tous les forfaits disponibles et sélectionner celui qui vous convient en cliquant sur le bouton ci-dessous.",
    courseQuestionFourth: "En quoi consiste le cours, quel est le programme?",
    courseAnswerFourth:
      "12 cours (7 cours de yoga, 3 cours avec exercices de respiration et 1 instruction vidéo de ma part, matériel théorique dans votre compte personnel).",
    courseQuestionFifth: "Quel matériel est nécessaire pour les cours ?",
    courseAnswerFifth:
      "Un tapis de yoga (de préférence, si vous n'en avez pas, vous pouvez utiliser n'importe quel tapis, une serviette), des vêtements confortables, un oreiller, une serviette (enfilez-la, aidez-vous), de l'eau et des huiles essentielles si vous le souhaitez.",
    courseButton: "Сhoisir un tarif",
    titlePackagesFirst: "Ce ",
    titlePackagesColor: "cours ",
    titlePackagesLast: "est fait pour vous si vous souhaitez:",
    courseAdvantageFirst: "sentez-vous léger, joyeux et plein d'énergie",
    courseAdvantageSecond:
      "normaliser le poids, éliminer l'enflure et améliorer le teint",
    courseAdvantageThird:
      "apprendre à pratiquer le yoga où et quand cela nous convient",
    courseAdvantageFourth:
      "rejoindre un club fermé où ils améliorent leur santé et leur corps",
    courseAdvantageFifth:
      "accédez à des cours thématiques de yoga avec des recommandations sur différents types de respiration, des huiles essentielles pour améliorer tous les aspects de la santé et du bien-être.",
    descriptorTariffs: "Les taux",
    titleTariffsFirst: "choisissez un programme ",
    titleTariffsColor: "adapté",
    tariffCardTitleFirst: "Indépendant",
    tariffCardDescriptionFirst:
      "• vidéo des mots d'adieu de Daria;\n• 10 cours de yoga;\n• 4 cours avec exercices de respiration;\n• matériel théorique: guides sur les chakras, les asanas et les huiles essentielles;\n• échauffement, étirements, équilibre et 2 ateliers avec cours collectifs.",
    tariffCardPriceFirst: "Coût: 129€",
    tariffCardTitleSecond: "Dans la communauté",
    tariffCardDescriptionSecond:
      "• vidéo des mots d'adieu de Daria;\n• 10 cours de yoga;\n• 4 cours avec exercices de respiration;\n• matériel théorique: guides sur les chakras, les asanas et les huiles essentielles;\n• échauffement, étirements, équilibre et 2 ateliers avec cours collectifs;\n• accès illimité aux cours en ligne via Zoom pendant un mois.",
    tariffCardPriceSecond: "Coût: 289€",
    tariffCardTitleThird: "Avec un mentor",
    tariffCardDescriptionThird:
      "• vidéo des mots d'adieu de Daria;\n• 10 cours de yoga;\n• 4 cours avec exercices de respiration;\n• matériel théorique: guides sur les chakras, les asanas et les huiles essentielles;\n• échauffement, étirements, équilibre et 2 ateliers avec cours collectifs;\n• accès illimité aux cours en ligne via Zoom pendant 3 mois;\n• 4 rendez-vous de 30 minutes chacun avec la création de votre pratique personnelle;\n• conversation personnelle avec Daria.",
    tariffCardPriceThird: "Coût: 549€",
    tariffButton: "acheter",
    videoCourseTitle:
      "Je vous souhaite la bienvenue dans mon cours en ligne «Je me ",
    videoCourseTitleColor: "sens",
    videoCourseTitleLast: "»",
    descriptorAddVideo: "Cours supplémentaires",
    titleAddVideoFirst: "un excellent ajout à ",
    titleAddVideoColor: "toute ",
    titleAddVideo: "classe de base",
    videoWarmUp: "réchauffer",
    videoStretching: "élongation",
    videoBalance: "équilibre",
    videoMeditation: "méditation",
    descriptorMainVideo: "Leçons clés",
    titleMainVideoFirst: "faites la pratique qui vous convient ",
    titleMainVideoColor: "maintenant",
    titleMainVideo: ". Quel est ton caractère?",
    mainVideoText:
      "Je recommande d'ajouter de l'huile essentielle à chaque cours, en fonction de l'état interne et du sujet du cours.",
    practiceConfident: "Je suis confiant",
    oilsConfident:
      "Huiles: Orange, Bergamote, Citron, Cannelle, Cassia, Motivate",
    breathConfident: "Souffle: feu, plein de yoga",
    practiceBalance: "Je suis en équilibre",
    oilsBalance: "Huiles: Balance, Lavande",
    breathBalance: "Souffle: alternative, plein de yoga",
    practiceStrong: "Je suis fort",
    oilsStrong: "Huiles: Orange, Menthe, Citron, Motivate",
    breathStrong: "Souffle: feu, alternative",
    practiceEnergetic: "Je suis énergique",
    oilsEnergetic: "Huiles: Orange, Menthe, Citron, Motivate",
    breathEnergetic: "Souffle: plein de yoga, feu",
    practiceSexy: "Je suis sexy",
    oilsSexy: "Huiles: Patchouli, Néroli, Rose, Agrumes",
    breathSexy: "Souffle: plein de yoga, alternative",
    practiceFree: "Je suis libre",
    oilsFree: "Huiles: Air, Menthe, Sapin, Sérénité",
    breathFree: "Souffle: plein de yoga",
    practiceFlexible: "Je suis flexible",
    oilsFlexible: "Huiles: Deep blue, Citron, Menthe",
    breathFlexible: "Souffle: toutes les parties du corps",
    practiceTender: "Je suis doux",
    oilsTender: "Huiles: Rose, Agrumes",
    breathTender: "Souffle: plein de yoga",
    practiceFocused: "Je suis concentré",
    oilsFocused: "Huiles: Menthe, Citron",
    breathFocused: "Souffle: plein de yoga + méditation légère",
    practiceFeel: "Je me sens",
    oilsFeel: "Huiles: Balance, Menthe",
    breathFeel: "Souffle: alternative",
    startBtn: "commencer",
    descriptorBreathVideo: "Leçons de respiration",
    titleBreathVideoFirst: "Complétez votre pratique avec ",
    titleBreathVideoColor: "la respiration yoga",
    breathVideoFirst: "Plein de yoga",
    breathVideoSecond: "Feu (Kapalbhati)",
    breathVideoThird: "En alternance (Nadi Shodhana)",
    breathVideoFourth: "Bonheur",
    descriptorWorkshopVideo: "Groupes de pratique",
    titleWorkshopVideo:
      "les pratiques de groupe vous aideront à diversifier vos pratiques",
    videoEnergyWorkshop: "énergie de pratique du yoga",
    videoCalmWorkshop: "pratique du yoga calme et équilibre",
    meditionHeaderTitle: "respiration\néquilibre\nharmonie",
    meditatiomMainTitle: "votre temps",
    meditationSubTitle: "pour la méditation",
    buttonBookNow: `Reserve`,
    bookDate: "Réservez une date et une heure qui vous conviennent",
    chooseTrainingType: "choisissez un format de formation pratique",
    bookingSummary: "Résumé de la réservation",
    dateBooking: "Ton rendez-vous",
    buttonBooking: "Confirme",
    member: "Déjà enregistré",
    loginBooking: "Connexion",
    personalcabinetpageData: "Vos données",
    goToCourseBtn: "Aller au cours",
    personalcabinetpagePrev: "Cours précédents",
    personalcabinetpageCurr: "Cours prévus",
    emptyBooking: "Pas de tables vacants",
    guidesText:
      "Ces guides vous aideront à en apprendre davantage sur le yoga et à mettre en œuvre ces connaissances dans vos pratiques.",
    essentialOilsGuide: "guide des huiles essentielles",
    dictionaryGuide: "guide des asanas du yoga",
    chackrasGuide: "guide des chakras",
    feedbackpage:
      "Partagez votre expérience - parlez-nous de vos impressions après la pratique, le cours, la méditation.",
    labelComment: "Place à votre avis",
    prevComment: "précédent",
    nextComment: "suivant",
    userCommentChat: "Parlons?",
    userCommentName: "Votre nom",
    userCommentEmail: "Votre email",
    userCommentSubject: "Sujet",
    userCommentMessage: "Votre message (facultatif)",
    userCommentBtn: "Soumettre",
    promotionTitle: "Retraite de yoga tout compris de 7 jours aux Maldives",
    promotionSubTitle: "25.01.2025 - 01.02.2025, 8 jours/7 nuits 1950€ + vol",
    promotionButton: "apprendre encore plus",
  },
  [LOCALES.GERMAN]: {
    descriptorAdvantages: "Vorteile",
    advantagesFirst: "Besonderer Ansatz",
    advantagesSecond: "Verschiedene Arten von Praktiken",
    advantagesThird: "Unterstützung im Yoga-Community-Chat",
    advantagesFourth: "Möglichkeit, online oder im Studio zu lernen",
    advantagesFifth: "Durchführung von Exerzitien",
    coverTitle: "dariya yoga",
    coverText:
      "Einzel- und Gruppenübungen, Online- und Offline-Kurse, Yoga für schwangere Frauen, Retreats und Yoga-Junggesellinnenabschiede auf der ganzen Welt.",
    introductionTitle: "Warum ich?",
    introductionExperience: "4+ Jahre Praxis",
    introductionPersonalApproach:
      "Individuelle Herangehensweise an jeden Kunden",
    introductionCommunity: "Eine große Yoga-Community glücklicher Menschen",
    aboutMeFirst:
      "Mein Name ist Daria, ich bin 31 Jahre alt. Jetzt lebe ich in Frankreich, habe drei Jahre in der Schweiz verbracht, bin ständig aufgeladen mit der Energie der Alpen, inspiriert von der Natur, den Elementen und der Schönheit um mich herum, und außerdem bin ich Mutter von zwei Kindern!",
    aboutMeSecond:
      "Durch das Praktizieren verschiedener Yoga-Stile entstand mein einzigartiger Stil, in dem Sie dynamischere Übungen, Rückenübungen, Entspannung, Dehnung sowie traditionelle Gleichgewichts-Asanas und Atemübungen finden.",
    descriptorPrices: "Preise und Abonnements",
    titlePricesFirst:
      "Für Online-Gruppenpraxen können Sie einen einmaligen Besuch oder ein Abonnement zu einem ",
    titlePricesColor: "günstigeren Preis ",
    titlePrices: "erwerben.",
    pricesPersonalLesson: "Einzelunterricht",
    pricesPersonalLessonDuration: "60 Minuten",
    pricesPersonalLessonPrice: "100€",
    pricesPersonalLessonZoom: "Persönlicher Unterricht im Zoom",
    pricesPersonalLessonZoomDuration: "60 Minuten",
    pricesPersonalLessonZoomPrice: "70€",
    pricesPrenatal: "Persönliche Praxis vor der Geburt",
    pricesPrenatalDuration: "60 Minuten",
    pricesPrenatalPrice: "100€",
    pricesPrenatalZoom: "Persönliche Praxis vor der Geburt im Zoom",
    pricesPrenatalZoomDuration: "60 Minuten",
    pricesPrenatalZoomPrice: "70€",
    pricesGroupPractice: "Gruppenunterricht",
    pricesGroupPracticeDuration: "60 Minuten",
    pricesGroupPracticePrice: "40€",
    pricesGroupPracticeZoom: "Gruppenunterricht auf Zoom",
    pricesGroupPracticeZoomDuration: "60 Minuten",
    pricesGroupPracticeZoomPrice: "25€",
    subscription4practices: "Abonnement für 4 Unterrichtsstunden",
    subscription4practicesPrice: "80€",
    subscription4practicesDuration: "60 Minuten",
    subscription8practices: "Abonnement für 8 Unterrichtsstunden",
    subscription8practicesPrice: "150€",
    subscription8practicesDuration: "60 Minuten",
    subscription12practices: "Abonnement für 12 Unterrichtsstunden",
    subscription12practicesPrice: "180€",
    subscription12practicesDuration: "60 Minuten",
    subscription16practices: "Abonnement für 16 Unterrichtsstunden",
    subscription16practicesPrice: "200€",
    subscription16practicesDuration: "60 Minuten",
    buttonInPriceCard: "Zahlen",
    descriptorAbout: "Über den Lehrer",
    titleAboutFirst: "Mein Name ist Daria, meine Yogareise begann ",
    titleAboutColor: "in Indien, ",
    titleAbout:
      "wo durch das Studium verschiedener Richtungen ein einzigartiger Stil entstand, in dem Sie etwas finden, das Ihnen nahe steht.",
    aboutFirstText:
      "Da ich in Frankreich lebe, bin ich ständig aufgeladen mit der Energie der Alpen, inspiriert von der Natur, den Elementen und der Schönheit um mich herum, dank derer ich einzigartige Rückzugsorte schaffe, Workshops leite und Menschen zum Yoga motiviere, und ich bin auch Mutter von zwei Kindern!",
    aboutSecondText:
      "In meinen Praxen finden Sie dynamische Übungen, Rückenübungen, Entspannung, Dehnübungen sowie traditionelle Balance-Asanas und Atemübungen.",
    descriptorTours: "Outdoor-Touren und Retreats",
    titleToursFirst: "Sie beherrschen Atemübungen und Meditation und lernen ",
    titleToursColor1: "den Yoga ",
    titleToursColor2: "-Lebensstil kennen,",
    titleTours: "Neue gesunde Gewohnheiten einführen, Asanas aufbauen.",
    toursPersonalTitle: "Persönlicher Rückzugsort",
    toursDurationPersonal: "1-3 Tage",
    toursPersonalDescription:
      "• Ausflug in die Alpen (Ort wird persönlich besprochen),\n • Yogapraxis,\n • Atmung,\n • Meditation,\n • Aromadiagnostik mit ätherischen Ölen,\n • Auswahl eines persönlichen Duftes,\n • Wandern mit Picknick oder Bergrestaurant,\n • intime Gespräche oder Praxis von Vipasana (Stille),\n • Besichtigung Thermalquellen mit Bädern (optional),\n • Bootsfahrt auf dem See (auf einigen Routen)",
    toursPersonalAddInfo:
      "Begleitung mit Auto oder Bahn, Foto-/Videoaufnahmen, Hilfe bei der Vorbereitung, Lösung organisatorischer Fragen inklusive",
    toursSoulTitle: "Körper und Seele",
    toursSoulDuration: "1-2 Tage",
    toursSoulDescription:
      "• Ausflug in die Thermalquellen der Schweiz,\n • Yogatherapie zu verschiedenen Zeiten (morgens/abends),\n • Badekunst,\n • Teetrinken,\n • Aromadiagnostik mit ätherischen Ölen,\n • Spa und Massage (auf Anfrage),\n • Meditation,\n • Atemübungen,\n • Spazierengehen/Wandern,\n • spirituelle Kommunikation",
    toursSoulAddInfo: "Begleitung mit dem Auto, Foto-/Videoaufnahmen",
    toursBaseTitle: "Nur Yoga",
    toursBaseDuration: "1 Tag",
    toursBaseDescription:
      "• Ausflug zu einem wunderschönen Schweizer Naturort,\n • Wandern oder Spazierengehen,\n • Yoga-Praxis,\n • Meditation mit den Elementen (Wasser, Erde, Feuer und Wasser),\n • Atemübungen,\n • Pranayama in den Bergen,\n • Selbstmassage,\n • Entspannung mit ätherischen Ölen,\n • gefühlvolle Gespräche,\n • Picknick mit Tee oder Bergrestaurant",
    toursBaseAddInfo:
      "Begleitung mit Auto oder Bahn, Fotos und Videos auf Anfrage",
    descriptorCorporate: "Unternehmenskooperation",
    titleCorporateFirst: "Yoga steigert die ",
    titleCorporateColor: "Produktivität ",
    titleCorporateSecond:
      "schafft eine harmonische Atmosphäre am Arbeitsplatz. Sie werden die ",
    titleCorporate:
      "inspirierenden Ergebnisse der Betreuung Ihrer Mitarbeiter genießen.",
    corporateFirstText:
      "Dies ist eine einzigartige Gelegenheit, das Team zu vereinen und die Möglichkeit zu bieten, neue Ideen zu entwickeln und gleichzeitig als Team zu üben.",
    corporateSecondText:
      "Abhängig vom allgemeinen körperlichen Niveau der Gruppe wählen wir die geeignete Yogarichtung und Häufigkeit der Übungen aus.",
    corporateThirdText:
      "Es ist möglich, Kurse online, in Ihrem Büro (Genf) oder in einem gemütlichen Studio im Zentrum von Genf durchzuführen.",
    corporateButton: "Finde mehr heraus",
    descriptorEssentialOils: "Essentielle Öle",
    titleEssentialOilsFirst: "Jeder ätherische",
    titleEssentialOilsColor: "Ölduft",
    titleEssentialOils:
      "ist einzigartig und unsere Empfindungen sprechen Bände.",
    essentialOilsFirstText:
      "Die Verwendung ätherischer Öle bei Yoga-Übungen und im Alltag ist eine ideale Ergänzung zur Erhaltung der körperlichen und emotionalen Gesundheit.",
    essentialOilsSecondText:
      "Ätherische Öle helfen, den Geist zu stabilisieren und den inneren Weg mit jedem Atemzug zu erhellen, stärken und verleihen dem Körper Flexibilität.",
    essentialOilsButton: "Öle bestellen",
    entranceWithGoogle: "Melden Sie sich mit Google an",
    login: "E-mail",
    password: "Passwort",
    incorrectPasswordErrorLength:
      "Das Passwort muss mindestens 6 Zeichen lang sein",
    incorrectPasswordErrorLetter:
      "Ihr Passwort muss mindestens einen Großbuchstaben enthalten",
    forgotPassportLink: "Passwort vergessen?",
    resetPasswordText:
      "Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihre E-Mail-Adresse ein",
    incorrectMailErrorEmpty: "Geben Sie bitte Ihre Email-Adresse ein",
    incorrectMailErrorValid:
      "Bitte überprüfen Sie, ob Ihre E-Mail-Adresse korrekt ist",
    errorLoginData:
      "Bitte überprüfen Sie Ihren eingegebenen Benutzernamen und Ihr Passwort.",
    resetPasswordSend: "schicken",
    resetPasswordCancel: "schließen",
    resetPasswordInstructionsTitle: "Passwort Wiederherstellung",
    resetPasswordInstructionsContent:
      "Bitte überprüfen Sie Ihre E-Mails auf Anweisungen zum Zurücksetzen des Passworts.",
    buttonAbout: "Mehr über mich",
    aboutREGText:
      "Registrieren Sie sich und finden Sie drei Autorenhandbücher in Ihrem persönlichen Konto!",
    name: "Name",
    // phone: "Telefonnummer",
    section_about: "Über mich",
    section_prices: "Preise",
    section_course: "Kurs",
    section_practices: "Praxis",
    section_meditation: "Meditation",
    section_contact: "Anrufen",
    section_reviews: "Bewertungen",
    section_Home: "Heim",
    section_booking: "Buchung",
    section_cabinet: "Persönliches Konto",
    section_Logout: "Ausloggen",
    section_tours: "Persönliche Touren",
    videoPractices:
      "Probeübungen werden Ihnen dabei helfen, eine solide Grundlage sowohl in den Yoga-Stellungen als auch in der Meditation zu entwickeln, sodass Sie selbstbewusst üben können.",
    menuButtonInvite: "Anmeldung",
    invitationToRegistration: "Neu bei Dariya_Yoga?",
    invitationToLogin: "Schon in der Welt des Yoga?",
    registration: "Registrieren",
    entrance: "Eingang",
    registrationButton: "Registrieren",
    pressPractices: "Klicke auf das gewünschte Körperteil 🤍",
    inspirationAreaTextOnImg: "Klick und lass dich inspirieren",
    buyEssensialOils: "Bestellen Sie ätherische Öle ✨",
    titleCertificate: "Sie können auf die Zertifikate klicken :)",
    certificateDescriptor: "Zertifikate",
    certificateFirst: "hatha yoga teacher",
    certificateSecond: "stretching",
    certificateThird: "pilates during pregnancy",
    certificateFourth: "hatha yoga teacher",
    certificateFifth: "pilates",
    descriptorReviews: "Rezensionen",
    titleReviewsFirst: "Was ",
    titleReviewsColor: "sagen ",
    titleReviews: "Kunden?",
    actionTitle: "Heute ist die beste Zeit!",
    actionText:
      "Praktiziere Yoga und werde Teil unserer Gemeinschaft glücklicher Menschen. Entdecken Sie neue Möglichkeiten, probieren Sie verschiedene Klassen und Niveaus aus!",
    footerText:
      "Beginnen Sie mit mir Ihre Reise zu einem schönen Körper, einem harmonischen Zustand innerer Ruhe und einem endlosen Energievorrat!",
    yogapageTextOneFirst:
      "Meine Herangehensweise an den Unterricht ist immer etwas Besonderes, ich stelle jede Lektion aus verschiedenen Übungen zusammen, basierend auf Ihren Wünschen.",
    yogapageTextOneSecond:
      "Die Unterrichtsdauer beträgt 60 Minuten: Wir beginnen mit Atmung und Tuning und enden mit einer kurzen Meditation. Einzel- und Gruppensitzungen sind möglich (maximal 5 Personen).",
    yogapageTextThree:
      "Bitte buchen Sie im Voraus, damit genügend Platz für alle vorhanden ist und die Zeit günstig ist. Schließlich ist eine gute Einstellung der Schlüssel zu Ihrer angenehmen Praxis.",
    practice_individual: "Individuell",
    practice_group: "Gruppenpraxis",
    practice_prenatal: "Prenatal",
    practice_kids: "Kids",
    practice_1:
      "Eine ideale Möglichkeit, Ihre Technik zu verbessern und fortgeschrittene Asanas zu erreichen.",
    practice_2:
      "Regelmäßige Kurse voller Energie, Motivation und Ausgeglichenheit.",
    practice_3:
      "Der Weg zu einer einfachen, aktiven Schwangerschaft und zur Unterstützung Ihres Körpers.",
    practice_4:
      "Kindern beibringen, mit ihrem Körper zu interagieren, harmonische Entwicklung.",
    buttonInPracticeCard: "Registrieren",
    descriptorCourse: "Online Kurs",
    titleCourseColor: "Ein origineller Kurs, ",
    titleCourse:
      "der darauf abzielt, inneres Gleichgewicht, tägliche Selbstunterstützung und Interaktion mit Ihrem Körper zu finden.",
    aboutCourse:
      "Der Kurs hilft dabei, Körper, Geist und Seele in einen harmonischen Zustand zu bringen. Es beinhaltet: eine umfassende Interaktion auf körperlicher und geistiger Ebene durch Asanas, Atemübungen und Meditationen.",
    courseQuestionFirst: "Welches Yoganiveau ist erforderlich?",
    courseAnswerFirst:
      "Der Kurs ist für jeden geeignet, auch für Menschen, die ihre ersten Schritte im Yoga machen. Nachdem Sie in verschiedenen Übungen viele Asanas und Techniken gemeistert haben, lernen Sie, in dem Moment, in dem Sie es brauchen, selbstständig zu üben. Wenn Sie jedoch schon lange praktizieren, werden Sie während des Kurses Ihr Wissen vertiefen und neue Komplexe kennenlernen und Übungen und verbessern Sie Ihre Technik. Die Schulung basiert auf einer klaren und durchdachten Methodik. Der Flow-Stil beinhaltet einen sanften Übergang zwischen den Yoga-Posen, sodass der Prozess selbst angenehm ist.",
    courseQuestionSecond: "Was erhalten Sie während des Kurses?",
    courseAnswerSecond:
      "Viele Möglichkeiten zum Aufwärmen, Übungen, Gelenkübungen, entspannende Asanas, Kraftelemente für alle Muskelgruppen, die variiert und in das eigenständige Üben einbezogen werden können. Ideen zum Verbinden und Aufbauen von Yoga Flow-Kursen, Atemtechniken sowie ein Wörterbuch mit Namen und eine Anleitung zu Chakren.",
    courseQuestionThird: "Was kostet der Kurs?",
    courseAnswerThird:
      "Die Kosten des Kurses richten sich nach dem gewählten Tarif. Sie können eine detaillierte Beschreibung aller verfügbaren Pakete anzeigen und das für Sie passende auswählen, indem Sie auf die Schaltfläche unten klicken.",
    courseQuestionFourth: "Woraus besteht der Kurs, was ist das Programm?",
    courseAnswerFourth:
      "12 Lektionen (7 Yogastunden, 3 Klassen mit Atemübungen und 1 Videoanleitung von mir, theoretische Materialien in Ihrem persönlichen Konto).",
    courseQuestionFifth:
      "Welche Materialien werden für den Unterricht benötigt?",
    courseAnswerFifth:
      "Eine Yogamatte (am besten, wenn Sie keine haben, können Sie einen beliebigen Teppich verwenden, ein Handtuch), bequeme Kleidung, ein Kissen, ein Handtuch (legen Sie es auf, bedienen Sie sich selbst), Wasser und ätherische Öle, falls gewünscht.",
    courseButton: "Wählen Sie einen Tarif",
    titlePackagesFirst: "Dieser ",
    titlePackagesColor: "Kurs ",
    titlePackagesLast: "ist für Sie, wenn Sie:",
    courseAdvantageFirst: "Fühlen Sie sich leicht, fröhlich und voller Energie",
    courseAdvantageSecond:
      "Normalisieren Sie das Gewicht, beseitigen Sie Schwellungen und verbessern Sie den Hautton",
    courseAdvantageThird:
      "Lernen Sie, Yoga zu praktizieren, wo und wann es Ihnen passt",
    courseAdvantageFourth:
      "Treten Sie einem geschlossenen Club bei, in dem sie ihre Gesundheit und ihren Körper verbessern",
    courseAdvantageFifth:
      "Erhalten Sie Zugang zu thematischen Yoga-Lektionen mit Empfehlungen zu verschiedenen Atemarten und ätherischen Ölen zur Verbesserung aller Aspekte der Gesundheit und des Wohlbefindens.",
    descriptorTariffs: "Tarife",
    titleTariffsFirst: "Wählen Sie ein geeignetes ",
    titleTariffsColor: "Programm",
    tariffCardTitleFirst: "Unabhängig",
    tariffCardDescriptionFirst:
      "• Video-Abschiedsworte von Daria;\n• 10 Yoga-Kurse;\n• 4 Unterrichtseinheiten mit Atemübungen;\n• theoretische Materialien: Anleitungen zu Chakren, Asanas und ätherischen Ölen;\n• Aufwärmen, Dehnen, Gleichgewicht und 2 Workshops mit Gruppenunterricht.",
    tariffCardPriceFirst: "Kosten: 129€",
    tariffCardTitleSecond: "In der Gemeinschaft",
    tariffCardDescriptionSecond:
      "• Video-Abschiedsworte von Daria;\n• 10 Yoga-Kurse;\n• 4 Unterrichtseinheiten mit Atemübungen;\n• theoretische Materialien: Anleitungen zu Chakren, Asanas und ätherischen Ölen;\n• Aufwärmen, Dehnen, Gleichgewicht und 2 Workshops mit Gruppenunterricht;\n• unbegrenzter Zugang zu Online-Kursen über Zoom für einen Monat.",
    tariffCardPriceSecond: "Kosten: 289€",
    tariffCardTitleThird: "Mit einem Mentor",
    tariffCardDescriptionThird:
      "• Video-Abschiedsworte von Daria;\n• 10 Yoga-Kurse;\n• 4 Unterrichtseinheiten mit Atemübungen;\n• theoretische Materialien: Anleitungen zu Chakren, Asanas und ätherischen Ölen;\n• Aufwärmen, Dehnen, Gleichgewicht und 2 Workshops mit Gruppenunterricht;\n• unbegrenzter Zugang zu Online-Kursen über Zoom für 3 Monate;\n• 4 Treffen à 30 Minuten mit der Erstellung Ihrer persönlichen Praxis;\n• Persönlicher Chat mit Daria.",
    tariffCardPriceThird: "Kosten: 549€",
    tariffButton: "kaufen",
    videoCourseTitle: "Ich begrüße Sie zu meinem Online-Kurs „Ich ",
    videoCourseTitleColor: "fühle mich ",
    videoCourseTitleLast: "selbst“",
    descriptorAddVideo: "Zusätzliche Lektionen",
    titleAddVideoFirst: "eine tolle Ergänzung für ",
    titleAddVideoColor: "jede ",
    titleAddVideo: "Basisklasse",
    videoWarmUp: "sich warm laufen",
    videoStretching: "Dehnung",
    videoBalance: "Gleichgewicht",
    videoMeditation: "Meditation",
    descriptorMainVideo: "Wichtige Lektionen",
    titleMainVideoFirst: "Machen Sie ",
    titleMainVideoColor: "jetzt ",
    titleMainVideo: "die Übung, die zu Ihnen passt. Wie bist du?",
    mainVideoText:
      "Ich empfehle, jeder Lektion ätherisches Öl hinzuzufügen, abhängig von Ihrem inneren Zustand und dem Thema des Kurses.",
    practiceConfident: "Ich bin zuversichtlich",
    oilsConfident: "Öle: Orange, Bergamotte, Zitrone, Zimt, Cassia, Motivate",
    breathConfident: "Atem: Feuer, voller Yoga",
    practiceBalance: "Ich bin im Gleichgewicht",
    oilsBalance: "Öle: Balance, Lavendel",
    breathBalance: "Atem: abwechselnd, voller Yoga",
    practiceStrong: "Ich bin stark",
    oilsStrong: "Öle: Orange, Minze, Zitrone, Motivate",
    breathStrong: "Atem: Feuer, abwechselnd",
    practiceEnergetic: "Ich bin voller Energie",
    oilsEnergetic: "Öle: Orange, Minze, Zitrone, Motivate",
    breathEnergetic: "Atem: voller Yoga, Feuer",
    practiceSexy: "Ich bin sexy",
    oilsSexy: "Öle: Patchouli, Neroli, Rose, Zitrusfrüchte",
    breathSexy: "Atem: voller Yoga, abwechselnd",
    practiceFree: "Ich bin frei",
    oilsFree: "Öle: Luft, Minze, Tanne, Gelassenheit",
    breathFree: "Atem: voller Yoga",
    practiceFlexible: "Ich bin flexibel",
    oilsFlexible: "Öle: Deep blue, Zitrone, Minze",
    breathFlexible: "Atem: alle Körperteile",
    practiceTender: "Ich bin sanft",
    oilsTender: "Öle: Rose, Zitrusfrüchte",
    breathTender: "Atem: voller Yoga",
    practiceFocused: "Ich bin im Fokus",
    oilsFocused: "Öle: Minze, Zitrone",
    breathFocused: "Atem: voller Yoga + leichte Meditation",
    practiceFeel: "Ich fühle mich",
    oilsFeel: "Öle: Balance, Minze",
    breathFeel: "Atem: abwechselnd",
    startBtn: "beginnen",
    descriptorBreathVideo: "Atemunterricht",
    titleBreathVideoFirst: "Ergänzen Sie Ihre Praxis mit ",
    titleBreathVideoColor: "Yoga-Atmung",
    breathVideoFirst: "Voller Yoga",
    breathVideoSecond: "Feuer (Kapalbhati)",
    breathVideoThird: "Abwechselnd (Nadi Shodhana)",
    breathVideoFourth: "Glück",
    descriptorWorkshopVideo: "Gruppenpraxis",
    titleWorkshopVideo:
      "Gruppenpraxen helfen Ihnen, Ihre Praxen zu diversifizieren",
    videoEnergyWorkshop: "Yoga-Praxis-Energie",
    videoCalmWorkshop: "Yoga übe Ruhe und Ausgeglichenheit",
    meditionHeaderTitle: "Harmonie\nder Atembalance",
    meditatiomMainTitle: "Deine Zeit",
    meditationSubTitle: "zum Meditieren",
    buttonBookNow: "Buchen Sie jetzt",
    bookDate:
      "Buchen Sie ein Datum und eine Uhrzeit, die für Sie geeignet sind",
    chooseTrainingType: "Wählen Sie ein geeignetes Schulungsformat",
    bookingSummary: "Buchungsübersicht",
    dateBooking: "Dein Date",
    buttonBooking: "Bestätige",
    member: "Bereits registriert",
    loginBooking: "Anmeldung",
    personalcabinetpageData: "Deine Daten",
    goToCourseBtn: "Gehe zum Kurs",
    personalcabinetpagePrev: "Frühere Lektionen",
    personalcabinetpageCurr: "Geplante Klassen",
    emptyBooking: "Keine freien Tische",
    guidesText:
      "Diese Leitfäden helfen Ihnen dabei, mehr über Yoga zu erfahren und das Wissen in Ihre Praktiken umzusetzen.",
    essentialOilsGuide: "Leitfaden für ätherische Öle",
    dictionaryGuide: "Leitfaden für Yoga-Asanas",
    chackrasGuide: "Chakra-Führer",
    feedbackpage:
      "Teilen Sie Ihre Erfahrung - erzählen Sie uns von Ihren Eindrücken nach der Praxis, dem Kurs, der Meditation.",
    labelComment: "Platz für Ihre Bewertung",
    prevComment: "bisherige",
    nextComment: "nächste",
    userCommentChat: "Lass uns reden?",
    userCommentName: "Ihr Name",
    userCommentEmail: "Deine E-Mail",
    userCommentSubject: "Thema",
    userCommentMessage: "Ihre Nachricht (optional)",
    userCommentBtn: "Schicken",
    promotionTitle: "7-tägiger All-Inclusive-Yoga-Retreat auf den Malediven",
    promotionSubTitle: "25.01.2025 - 01.02.2025, 8 Tage/7 Nächte 1950€ + Flug",
    promotionButton: "Lern mehr",
  },
};
