import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import certificate from "../../assets/certificate.png";
import certificateHatha from "../../assets/certificateHatha.jpeg";
import certificatePilates from "../../assets/certificatePilates.PNG";
import certificatePregnance from "../../assets/certificatePregnance.JPG";
import certificateStretching from "../../assets/certificateStretching.JPG";
import certificateYogaSchool from "../../assets/certificateYogaSchool.jpeg";
import "./Certificates.css";

export default function Certificates() {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleListItemClick = (event, index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  const certificates = [
    { title: "certificateFirst", img: certificateHatha },
    { title: "certificateSecond", img: certificateStretching },
    { title: "certificateThird", img: certificatePregnance },
    { title: "certificateFourth", img: certificateYogaSchool },
    { title: "certificateFifth", img: certificatePilates },
    { title: "certificateFourth", img: certificate },
  ];

  return (
    <div className="certificatesContainer">
      <p className="descriptor">
        <FormattedMessage id="certificateDescriptor" />
      </p>
      <List component="nav" className="certificatesList">
        {certificates.map((certificate, index) => (
          <ListItemButton
            className="certificateContent"
            key={"certificate" + index}
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemText
              className={
                selectedIndex === index
                  ? "certificateTitle activeCertificate"
                  : "certificateTitle"
              }
            >
              <FormattedMessage id={certificate.title} />
              {selectedIndex === index ? (
                <img
                  src={certificate.img}
                  alt="..."
                  className="certificateImg"
                />
              ) : null}
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </div>
  );
}
