import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import languageIcon from "../../assets/language.svg";
import login from "../../assets/login.svg";
import menuIcon from "../../assets/menu.svg";
import userIcon from "../../assets/user.svg";
import { sections } from "../../data";
import { LOCALES } from "../../i18n/locales";
import { getSession, isLoggedIn } from "../../storage/session";
import { scrollTo } from "../../utils";
import "./NavigationMenu.css";

const languages = [
  { name: "English", code: LOCALES.ENGLISH },
  { name: "Русский", code: LOCALES.RUSSIAN },
  { name: "Français", code: LOCALES.FRENCH },
  { name: "Deutsche", code: LOCALES.GERMAN },
];

export default function NavigationMenu({
  currentLocale,
  handleChange,
  mobileView,
  browserView,
}) {
  const user = useMemo(() => getSession(), []);
  const [showSelect, setShowSelect] = useState(false);

  const showLanguageSelect = () => {
    setShowSelect(true);
  };

  return (
    <div>
      {browserView && (
        <div className="navigationContainer">
          <div className="logo">
            <FormattedMessage id={"coverTitle"} />
          </div>
          <div className="navigationItems">
            <button key="Booking" className="headerNavBtn">
              <Link to={"/booking"} className="btn">
                <FormattedMessage id={"section_booking"} />
              </Link>
            </button>

            {sections.map((section) => (
              <button
                key={section.id + "sectionHeader"}
                onClick={() => scrollTo(section.id)}
                variant="text"
                className="headerNavBtn btn"
              >
                <FormattedMessage id={"section_" + section.id} />
              </button>
            ))}
            {user.uid === "MOHRB8XMNFetVsAU4GTwW2NvCDK2" ||
            user.uid === "fJPswP2SjtNHpiBCZ350ZBbqBoR2" ? (
              <Link to={"/admin"} className="btn">
                <p>admin</p>
              </Link>
            ) : (
              ""
            )}
          </div>

          <div className="headerActions">
            <button className="headerNavBtn">
              {isLoggedIn() ? (
                <Link to={"/cabinet"} className="btn">
                  <img src={userIcon} alt="" />
                </Link>
              ) : (
                <Link to={"/registration"} className="btn">
                  <button className="entranceBtn">
                    <FormattedMessage id={"entrance"} />
                  </button>
                </Link>
              )}
            </button>
            {showSelect ? (
              <Select value={currentLocale} onChange={handleChange}>
                {languages.map(({ name, code }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <button
                className="headerNavBtn languageBtn"
                onClick={showLanguageSelect}
              >
                <img src={languageIcon} alt="" />
              </button>
            )}
          </div>
        </div>
      )}
      {mobileView && (
        <div className="navigationContainer">
          <div className="logo">
            <FormattedMessage id={"coverTitle"} />
          </div>
          <div className="headerActions">
            <button className="headerNavBtn">
              {isLoggedIn() ? (
                <Link to={"/cabinet"} className="btn">
                  <img src={userIcon} alt="" />
                </Link>
              ) : (
                <Link to={"/registration"} className="btn">
                  <button className="entranceBtn">
                    <FormattedMessage id={"entrance"} />
                  </button>
                </Link>
              )}
            </button>
            {showSelect ? (
              <Select value={currentLocale} onChange={handleChange}>
                {languages.map(({ name, code }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <button
                className="headerNavBtn languageBtn"
                onClick={showLanguageSelect}
              >
                <img src={languageIcon} alt="" />
              </button>
            )}
            {/* <button className="headerNavBtn">
              <img src={menuIcon} alt="" />
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
}
