import support from "../assets/support.jpg";
import approach from "../assets/approach.jpg";
import differentPractice from "../assets/differentPractice.jpg";
import studioPractice from "../assets/studioPractice.jpg";
import retreat from "../assets/retreat.jpg";

export const advantages = [
  { img: approach, title: "advantagesFirst" },
  { img: differentPractice, title: "advantagesSecond" },
  { img: support, title: "advantagesThird" },
  { img: studioPractice, title: "advantagesFourth" },
  { img: retreat, title: "advantagesFifth" },
];
