
const week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];


export function getCurrentDay(date) {
  return week[new Date(date).getDay()]
}
