import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { endSession } from "../storage/session";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export const storage = getStorage(app);
export const firebaseDB = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  return signInWithPopup(auth, googleProvider);
};

export const signInUser = async (data) => {
  return signInWithEmailAndPassword(auth, data.email, data.password)
    .then((userCredential) => {
      return userCredential.user;
    })
    .catch((error) => {
      return error.message;
    });
};

export const createUser = async (data) => {
  return createUserWithEmailAndPassword(auth, data.email, data.password);
};

export const resetUserPassword = async (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const logout = () => {
  signOut(auth);
  endSession();
};
