import React from "react";
import { FormattedMessage } from "react-intl";
import nature from "../../assets/nature.jpg";
import deepDream from "../../media/deepDream.mp3";
import peaceOfMind from "../../media/peaceOfMind.mp3";
import sakura from "../../media/sakura.mp3";
import "./Meditation.css";

export default function Meditation() {
  const sounds = [sakura, peaceOfMind, deepDream];

  return (
    <div className="meditationBox">
      <div className="substrate">
        <span className="meditionHeaderTitle">
          <FormattedMessage id={"meditionHeaderTitle"} />
        </span>
        <span className="meditatiomMainTitle">
          <FormattedMessage id={"meditatiomMainTitle"} />
        </span>
        <span className="meditationSubTitle">
          <FormattedMessage id={"meditationSubTitle"} />
        </span>
        <div className="audioBox">
          {sounds.map((sound, index) => {
            return (
              <audio
                style={{ height: "30px" }}
                controls
                src={sound}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <img src={nature} alt="..." className="img" />
    </div>
  );
}
