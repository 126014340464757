import { getDocuments, firebaseDB } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

export const getAllData = async () => {
  try {
    const allBookings = await getDocuments("bookings");
    const allFeedbacks = await getDocuments("feedback");
    const schedule = await getDocuments("schedule");

    return { allBookings, allFeedbacks, schedule };
  } catch (error) {
    console.error(error);
  }
};

export const editScheduleInfo = async ({ day, time, info }) => {
  try {
    const schedule = doc(firebaseDB, "schedule", "zqGywi1HMI6Ou7ohpLIT");

    await updateDoc(schedule, {
      [`${day}.${time}`]: info,
    });
  } catch (error) {
    console.error(error);
  }
};
