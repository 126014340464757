import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import blackPoint from "../../assets/blackPoint.svg";
import whitePoint from "../../assets/whitePoint.svg";
import { advantages } from "../../data";
import "./Advantages.css";

export default function Advantages({ browserView }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <div>
      <div className="descriptor">
        <FormattedMessage id="descriptorAdvantages" />
      </div>
      <div className="advantagesBox">
        <List component="nav">
          {advantages.map((advantage, index) => (
            <ListItemButton
              key={"listItem" + index}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
              className={
                selectedIndex === index ? "listItem activeListItem" : "listItem"
              }
            >
              <ListItemText
                primary={"0" + (index + 1)}
                className="listItemNum"
              />
              {selectedIndex === index && browserView ? (
                <img src={advantage.img} alt="..." className="advantageImg" />
              ) : null}
              <ListItemText
                primary={<FormattedMessage id={advantage.title} />}
                className="listItemText"
              />
              <ListItemIcon className="listItemPoint">
                {selectedIndex === index ? (
                  <img src={blackPoint} alt="..." />
                ) : (
                  <img src={whitePoint} alt="..." />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </div>
    </div>
  );
}
