import React from "react";
import { FormattedMessage } from "react-intl";
import "./Reviews.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import videoReview1 from "../../assets/videoReview1.mp4";
import videoReview2 from "../../assets/videoReview2.mp4";
import videoReview3 from "../../assets/videoReview3.mp4";
import videoReview4 from "../../assets/videoReview4.mp4";
import videoReview5 from "../../assets/videoReview5.mp4";

export default function Reviews() {
  const video = [
    videoReview1,
    videoReview2,
    videoReview3,
    videoReview4,
    videoReview5,
  ];
  return (
    <div className="reviewsContainer">
      <div className="reviewsTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorReviews" />
        </p>
        <p className="titleReviews">
          <span>
            <FormattedMessage id="titleReviewsFirst" />
          </span>
          <span className="titleReviewsColor">
            <FormattedMessage id="titleReviewsColor" />
          </span>
          <FormattedMessage id="titleReviews" />
        </p>
      </div>
      <Carousel
        className="reviewVideo"
        centerMode
        showStatus={false}
        showThumbs={false}
        infiniteLoop
      >
        {video.map((v, index) => {
          return (
            <ReactPlayer url={v} controls={true} width={"100%"} key={index} />
          );
        })}
      </Carousel>
    </div>
  );
}
