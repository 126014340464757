import NightlightIcon from "@mui/icons-material/Nightlight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { createBooking, showAllBookings, showAvailableTime } from "../../api";
import { getSession, isLoggedIn } from "../../storage/session";
import { getCurrentDay } from "../../utils";
import "./Booking.css";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  ru: "__.__.____",
  de: "__.__.____",
};

export default function Booking() {
  const navigate = useNavigate();

  const user = getSession();

  const [selectedDate, setSelectedDate] = useState(
    format(Date.now(), "yyyy-MM-dd")
  );
  const [trainingInfo, setTrainingInfo] = useState({});
  const [trainingData, setTrainingData] = useState([]);
  const [locale, setLocale] = useState("en");
  const [bookings, setBookings] = useState([]);
  const [slots, setSlots] = useState({});

  useEffect(() => {
    (async () => {
      const bookData = await showAllBookings();
      setBookings(bookData);

      const availableSlots = await showAvailableTime();
      setSlots(availableSlots);
    })();
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const selectLocale = (newLocale) => {
    setLocale(newLocale);
  };

  const booking = useMemo(() => {
    const hash = {};

    bookings?.forEach((book) => {
      const bookingDate = format(new Date(book.date), "yyyy-MM-dd");

      if (!hash[bookingDate]) {
        hash[bookingDate] = [book.time];
      } else {
        hash[bookingDate].push(book.time);
      }
    });

    return hash;
  }, [bookings]);

  useEffect(() => {
    const day = getCurrentDay(selectedDate);

    const data = slots[day]
      ?.map(({ time, groupType, trainingType, personCount, format }) => ({
        time,
        groupType,
        trainingType,
        personCount,
        format,
        isNotAvailable: false,
      }))
      .map((td) => {
        let count = 0;
        booking[selectedDate]?.forEach((book) => {
          if (book === td.time) {
            count++;
          }
        });
        if (count >= td.personCount) {
          td.isNotAvailable = true;
        }
        return td;
      });

    setTrainingData(data);
  }, [selectedDate, slots, booking]);

  const getDisabledDates = (date) => {
    const day = getCurrentDay(date);

    return day === "Saturday";
  };

  const handleDatePicker = (value) => {
    const date = format(value, "yyyy-MM-dd");
    setSelectedDate(date);
  };

  const handleTimePicker = (value) => {
    setTrainingInfo(value);
  };

  const handleBooking = () => {
    createBooking(selectedDate, trainingInfo.time, user);
    window.open(
      `https://wa.me/79030031642?text=Здравствуйте,+я+хочу+оплатить+практику+${selectedDate}+в+${trainingInfo.time}+формат практики:+${trainingInfo.format}`,
      "_blank"
    );
    navigate("/cabinet");
  };

  return (
    <div className="boxBooking">
      <div className="containerBooking">
        <div className="bookDate">
          <h2>
            <FormattedMessage id="bookDate" />
          </h2>
          <div className="picker">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <div>
                <ToggleButtonGroup
                  className="buttonsLanguage"
                  value={locale}
                  exclusive
                  sx={{ mb: 2, display: "block" }}
                >
                  {Object.keys(localeMap).map((localeItem, index) => (
                    <ToggleButton
                      key={index}
                      value={localeItem}
                      onClick={() => selectLocale(localeItem)}
                    >
                      {localeItem}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <StaticDatePicker
                  mask={maskMap[locale]}
                  orientation="landscape"
                  openTo="day"
                  value={selectedDate}
                  shouldDisableDate={getDisabledDates}
                  onChange={handleDatePicker}
                  renderInput={(props) => <TextField {...props} />}
                  minDate={new Date("2022-04-04")}
                />
              </div>
            </LocalizationProvider>
            <div className="timeButtons">
              {trainingData?.map(
                (
                  { time, groupType, trainingType, isNotAvailable, format },
                  index
                ) => {
                  return (
                    <Tooltip
                      key={index}
                      title={
                        isNotAvailable ? (
                          <FormattedMessage id={"emptyBooking"} />
                        ) : (
                          ""
                        )
                      }
                      placement="right"
                    >
                      {trainingType === "morning" ? (
                        <span className="buttonInBooking">
                          <Button
                            disabled={isNotAvailable}
                            onClick={() =>
                              handleTimePicker({ time, groupType, format })
                            }
                            startIcon={<WbSunnyIcon />}
                          >
                            {groupType === "group" ? (
                              <span>
                                <FormattedMessage id="practice_group" />
                              </span>
                            ) : (
                              <span>
                                <FormattedMessage id="practice_individual" />
                              </span>
                            )}
                            <span>{time}</span>
                            <span>{format}</span>
                          </Button>
                        </span>
                      ) : (
                        <span className="buttonInBooking">
                          <Button
                            disabled={isNotAvailable}
                            onClick={() =>
                              handleTimePicker({ time, groupType, format })
                            }
                            startIcon={<NightlightIcon />}
                          >
                            {groupType === "group" ? (
                              <span>
                                <FormattedMessage id="practice_group" />
                              </span>
                            ) : (
                              <span>
                                <FormattedMessage id="practice_individual" />
                              </span>
                            )}
                            <span>{time}</span>
                            <span>{format}</span>
                          </Button>
                        </span>
                      )}
                    </Tooltip>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="containerBookSum">
          <h2>
            <FormattedMessage id="bookingSummary" />
          </h2>
          <p className="dateAndPracticeP">
            <FormattedMessage id="dateBooking" /> : {selectedDate}{" "}
            {trainingInfo.time} {trainingInfo.format}
          </p>
          {isLoggedIn() ? (
            <СontainedButton>
              <span onClick={handleBooking}>
                <FormattedMessage id="buttonBooking" />
              </span>
            </СontainedButton>
          ) : (
            <>
              <p className="dateAndPracticeP">
                <FormattedMessage id="member" />?
              </p>
              <Link href="/login" className="dateAndPracticeP">
                <FormattedMessage id="loginBooking" />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
