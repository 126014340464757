import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import img from "../../assets/yoga.jpg";
import { СontainedButton } from "../Buttons/ContainedButton/ContainedButton";
import "./Action.css";

export default function Action({ mobileView, browserView }) {
  return (
    <div className="actionContainer">
      {browserView && (
        <div>
          <div className="border" />
          <div className="actionBox">
            <span className="actionTitle">
              <FormattedMessage id="actionTitle" />
            </span>
            <span className="actionText">
              <FormattedMessage id="actionText" />
            </span>
            <Link to={"/booking"} className="btn">
              <СontainedButton>
                <FormattedMessage id="buttonBookNow" />
              </СontainedButton>
            </Link>
          </div>
          <img src={img} alt="" className="yogaPeopleImg" />
        </div>
      )}
      {mobileView && (
        <div className="border">
          <span className="actionTitle">
            <FormattedMessage id="actionTitle" />
          </span>
          <span className="actionText">
            <FormattedMessage id="actionText" />
          </span>
          <img src={img} alt="" className="yogaPeopleImg" />
          <Link to={"/booking"}>
            <button className="actionButton">
              <FormattedMessage id="buttonBookNow" />
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}
