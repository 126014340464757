import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { editScheduleInfo, getAllData } from "../../api/admin";
import "./Admin.css";

export default function Admin() {
  const [bookings, setBookings] = useState([]);
  const [schedule, setSchedule] = useState([]);
  // const [feedbacks, setFeedbacks] = useState([]);
  useEffect(() => {
    getAllData().then((result) => {
      setBookings(
        result.allBookings.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        })
      );
      setSchedule(result.schedule);
      // setFeedbacks(result.allFeedbacks);
    });
  }, []);


  const editSchedule = (value) => {
    editScheduleInfo(value);
  };

  return (
    <div className="adminPage">
      <div className="adminPagePersonBlock">
        <h2>Бронирования</h2>

        <div className="personDataForAdmin">
          {bookings.map((book, id) => {
            return (
              <ul key={id} className="bookingBlock">
                <li>
                  <div className="bookingInfo">
                    <span>{format(new Date(book.date), "dd-MM-yyyy")}</span>
                    <span>{book.time}</span>
                  </div>
                  <span className="bookingInfoName">{book.userName}</span>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
      {/* <div className="adminPageFeedbacksBlock">
        <h2>Отзывы</h2>
        {feedbacks?.map((feedback, id) => {
          return <li key={id}>{feedback.text}</li>;
        })}
      </div> */}
      <div className="adminPageScheduleBlock">
        <h2>Расписание</h2>
        {schedule.length
          ? Object.entries(schedule[0]).map(([day, info]) => {
              if (day === "id") return null;
              return (
                <div key={day} className="dayInSchedule">
                  <h1>{day}</h1>
                  {Object.keys(info).map((time, index) => {
                    return (
                      <div key={index} className="scheduleInfo">
                        <h2>{time}</h2>
                        <span>
                          <p>Количество:</p>
                          <span>{info[time][0]}</span>
                          <p>Тип группы:</p>
                          <span>{info[time][1]}</span>
                          {/* <EditIcon onClick={() => editSchedule()} /> */}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : "Подожди, данные загружаются:)"}
      </div>
    </div>
  );
}
