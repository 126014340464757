import React from "react";
import { FormattedMessage } from "react-intl";
import about1 from "../../assets/about1.jpg";
import about2 from "../../assets/about2.jpg";
import about3 from "../../assets/about3.jpg";
import about4 from "../../assets/about4.jpg";
import about5 from "../../assets/about5.jpg";
import "./About.css";

export default function About({ mobileView, browserView }) {
  return (
    <div className="aboutContainer">
      <div className="aboutTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorAbout" />
        </p>
        <p className="titleAbout">
          <span className="titleAboutFirst">
            <FormattedMessage id="titleAboutFirst" />
          </span>
          <span className="titleAboutColor">
            <FormattedMessage id="titleAboutColor" />
          </span>
          <FormattedMessage id="titleAbout" />
        </p>
      </div>
      <div className="aboutMainText">
        <span className="firstParagraghAbout">
          <FormattedMessage id="aboutFirstText" />
        </span>
        <span>
          <FormattedMessage id="aboutSecondText" />
        </span>
      </div>
      {browserView && (
        <div className="imagesAboutBox">
          <img src={about1} alt="" className="aboutImg" />
          <span className="secondPhotoGroup">
            <img src={about2} alt="" className="aboutImg specialImgAbout" />
            <img src={about3} alt="" className="aboutImg" />
          </span>
          <span className="thirdPhotoGroup">
            <img src={about4} alt="" className="aboutImg specialImgAbout" />
            <img src={about5} alt="" className="aboutImg" />
          </span>
        </div>
      )}

      {mobileView && (
        <div className="imagesAboutBox">
          <img src={about1} alt="" className="aboutImg" />
          <img src={about2} alt="" className="aboutImg specialImgAbout" />
        </div>
      )}
    </div>
  );
}
