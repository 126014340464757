import React from "react";
import { FormattedMessage } from "react-intl";
import "./EssentialOils.css";
import oilsImg from "../../assets/essentialOils.png";
import { TextButton } from "../Buttons/TextButton/TextButton";

export default function EssentialOils() {
  return (
    <div className="essentialOilsContainer">
      <div className="essentialOilsTitleBox">
        <p className="descriptor">
          <FormattedMessage id="descriptorEssentialOils" />
        </p>
        <p className="titleEssentialOils">
          <span className="titleEssentialOilsFirst">
            <FormattedMessage id="titleEssentialOilsFirst" />
          </span>
          <span className="titleEssentialOilsColor">
            <FormattedMessage id="titleEssentialOilsColor" />
          </span>
          <FormattedMessage id="titleEssentialOils" />
        </p>
      </div>
      <div className="essentialOilsMainText">
        <span className="firstParagraghOils">
          <FormattedMessage id="essentialOilsFirstText" />
        </span>
        <span>
          <FormattedMessage id="essentialOilsSecondText" />
        </span>
      </div>
      <TextButton>
        <a href="https://doterra.me/Jp4VvZ" target="_blank" rel="noreferrer">
          <FormattedMessage id="essentialOilsButton" />
        </a>
      </TextButton>
      <img src={oilsImg} alt="" className="essentialOilsImg" />
    </div>
  );
}
