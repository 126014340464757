import videoBalance from "../assets/videoBalance.JPG";
import videoBrave from "../assets/videoBrave.JPG";
import videoEnergy from "../assets/videoEnergy.JPG";
import videoFeel from "../assets/videoFeel.JPG";
import videoFlexible from "../assets/videoFlexible.JPG";
import videoFocus from "../assets/videoFocus.jpg";
import videoFree from "../assets/videoFree.JPG";
import videoSexy from "../assets/videoSexy.JPG";
import videoStrong from "../assets/videoStrong.jpg";
import videoTender from "../assets/videoTender.PNG";

export const questions = [
  { id: 1, title: "courseQuestionFirst", description: "courseAnswerFirst" },
  { id: 2, title: "courseQuestionSecond", description: "courseAnswerSecond" },
  { id: 3, title: "courseQuestionThird", description: "courseAnswerThird" },
  { id: 4, title: "courseQuestionFourth", description: "courseAnswerFourth" },
  { id: 5, title: "courseQuestionFifth", description: "courseAnswerFifth" },
];

export const courseAdvantages = [
  { id: 1, title: "courseAdvantageFirst" },
  { id: 2, title: "courseAdvantageSecond" },
  { id: 3, title: "courseAdvantageThird" },
  { id: 4, title: "courseAdvantageFourth" },
  { iid: 5, title: "courseAdvantageFifth" },
];

export const packages = [
  {
    id: 1,
    title: "tariffCardTitleFirst",
    description: "tariffCardDescriptionFirst",
    price: "tariffCardPriceFirst",
    cost: "100€",
  },
  {
    id: 2,
    title: "tariffCardTitleSecond",
    description: "tariffCardDescriptionSecond",
    price: "tariffCardPriceSecond",
    cost: "200€",
  },
  {
    id: 3,
    title: "tariffCardTitleThird",
    description: "tariffCardDescriptionThird",
    price: "tariffCardPriceThird",
    cost: "500€",
  },
];

export const addVideo = [
  {
    id: 1,
    title: "videoWarmUp",
    link: "http://www.youtube.com/embed/7K0a9LAP0jg?si=Z9vDW4LUFiQCZpnD",
  },
  {
    id: 2,
    title: "videoStretching",
    link: "http://www.youtube.com/embed/qxtU6Hssvas?si=J4O_Eo4ueK46HhvS",
  },
  {
    id: 3,
    title: "videoBalance",
    link: "http://www.youtube.com/embed/OJpnwPzcd8k?si=9OOboNUtHP5I8D1m",
  },
  {
    id: 4,
    title: "videoMeditation",
    link: "http://www.youtube.com/embed/lQLVOHWzg54?si=GIzC1-jRHIU22Nm8",
  },
];

export const mainVideoFirstGroup = [
  {
    id: 1,
    title: "practiceConfident",
    oils: "oilsConfident",
    breath: "breathConfident",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/UGEBH2ExbGo?si=v5SyQn0XJZoo_zkQ",
    img: videoBrave,
  },
  {
    id: 2,
    title: "practiceBalance",
    oils: "oilsBalance",
    breath: "breathBalance",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/GTAXVYRcXto?si=vO6-jz0IiwOniE0Q",
    img: videoBalance,
  },
  {
    id: 3,
    title: "practiceStrong",
    oils: "oilsStrong",
    breath: "breathStrong",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/6k83g0RQCtk?si=5eYCcncRpxaccnXp",
    img: videoStrong,
  },
  {
    id: 4,
    title: "practiceEnergetic",
    oils: "oilsEnergetic",
    breath: "breathEnergetic",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/CdgvqfN02Rs?si=jVIm214CgxiC19CR",
    img: videoEnergy,
  },
];

export const mainVideoSecondGroup = [
  {
    id: 1,
    title: "practiceSexy",
    oils: "oilsSexy",
    breath: "breathSexy",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/",
    img: videoSexy,
  },
  {
    id: 2,
    title: "practiceFree",
    oils: "oilsFree",
    breath: "breathFree",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/nbWcB4E_Ijk?si=5o9xEAtLUpSc7qIo",
    img: videoFree,
  },
  {
    id: 3,
    title: "practiceFlexible",
    oils: "oilsFlexible",
    breath: "breathFlexible",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/l76ZlKhWeoI?si=o353U5tlJMKXZWOd",
    img: videoFlexible,
  },
  {
    id: 4,
    title: "practiceTender",
    oils: "oilsTender",
    breath: "breathTender",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/YM9n0s4L47A?si=hz7zJvu8-NanB7P6",
    img: videoTender,
  },
];

export const mainVideoThirdGroup = [
  {
    id: 1,
    title: "practiceFocused",
    oils: "oilsFocused",
    breath: "breathFocused",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/BFpMa3V2sqw?si=17MzlIFRluldGvMw",
    img: videoFocus,
  },
  {
    id: 2,
    title: "practiceFeel",
    oils: "oilsFeel",
    breath: "breathFeel",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/vnlusxbBHYE?si=v17g20QUrl9P0FBw",
    img: videoFeel,
  },
];

export const mainVideoForMobile = [
  {
    id: 1,
    title: "practiceConfident",
    oils: "oilsConfident",
    breath: "breathConfident",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/UGEBH2ExbGo?si=v5SyQn0XJZoo_zkQ",
    img: videoBrave,
  },
  {
    id: 2,
    title: "practiceBalance",
    oils: "oilsBalance",
    breath: "breathBalance",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/GTAXVYRcXto?si=vO6-jz0IiwOniE0Q",
    img: videoBalance,
  },
  {
    id: 3,
    title: "practiceStrong",
    oils: "oilsStrong",
    breath: "breathStrong",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/6k83g0RQCtk?si=5eYCcncRpxaccnXp",
    img: videoStrong,
  },
  {
    id: 4,
    title: "practiceEnergetic",
    oils: "oilsEnergetic",
    breath: "breathEnergetic",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/CdgvqfN02Rs?si=jVIm214CgxiC19CR",
    img: videoEnergy,
  },
  {
    id: 5,
    title: "practiceSexy",
    oils: "oilsSexy",
    breath: "breathSexy",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/1-zd2ucVYjc?si=DkyDNDyFhbcVA63y",
    img: videoSexy,
  },
  {
    id: 6,
    title: "practiceFree",
    oils: "oilsFree",
    breath: "breathFree",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/2TNmxCsj0dQ?si=iBXwbphTzBpbhm04",
    img: videoFree,
  },
  {
    id: 7,
    title: "practiceFlexible",
    oils: "oilsFlexible",
    breath: "breathFlexible",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/l76ZlKhWeoI?si=o353U5tlJMKXZWOd",
    img: videoFlexible,
  },
  {
    id: 8,
    title: "practiceTender",
    oils: "oilsTender",
    breath: "breathTender",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/YM9n0s4L47A?si=hz7zJvu8-NanB7P6",
    img: videoTender,
  },
  {
    id: 9,
    title: "practiceFocused",
    oils: "oilsFocused",
    breath: "breathFocused",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/BFpMa3V2sqw?si=17MzlIFRluldGvMw",
    img: videoFocus,
  },
  {
    id: 10,
    title: "practiceFeel",
    oils: "oilsFeel",
    breath: "breathFeel",
    btnTitle: "startBtn",
    link: "http://www.youtube.com/embed/vnlusxbBHYE?si=v17g20QUrl9P0FBw",
    img: videoFeel,
  },
];

export const breathVideo = [
  {
    id: 1,
    title: "breathVideoFirst",
    link: "http://www.youtube.com/embed/t0pV31xZQzo?si=FWX_Hz8oVDslGqEK",
  },
  {
    id: 2,
    title: "breathVideoSecond",
    link: "http://www.youtube.com/embed/d7R223ir3z4?si=Vmm7COV-gtw54u_l",
  },
  {
    id: 3,
    title: "breathVideoThird",
    link: "http://www.youtube.com/embed/jvLM6xmSs_E?si=NTDCIb_ewEXsy0ch",
  },
  {
    id: 4,
    title: "breathVideoFourth",
    link: "http://www.youtube.com/embed/AJyEel-JVSY?si=4l6anR2aXAlaZUWc",
  },
];

export const workshopVideo = [
  {
    id: 1,
    title: "videoEnergyWorkshop",
    link: "http://www.youtube.com/embed/rLpiFK-0GIE?si=fmfFjJ8bJtn1s4_B",
  },
  {
    id: 2,
    title: "videoCalmWorkshop",
    link: "http://www.youtube.com/embed/WsCU1h94BMo?si=dXlRFRzG3LWDtOL7",
  },
];
