import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { practices } from "../../data/practices";
import "./Practices.css";

export default function Practices({ responsive, mobileView, browserView }) {
  return (
    <div>
      <div className="descriptor">
        <FormattedMessage id="section_practices" />
      </div>
      {browserView && (
        <div className="practicesBox">
          {practices.map((type, index) => (
            <Card
              key={"practiceCard" + index}
              className="practiceCard"
              style={{ backgroundImage: `url(${type.img})` }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="practiceCardTitle"
                >
                  <FormattedMessage id={`${type.title}`} />
                </Typography>
              </CardContent>
              <CardActions className="practiceCardFooter">
                <Typography variant="body2" className="practiceCardDescription">
                  <FormattedMessage id={type.description} />
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  className="buttonInPracticeCard"
                >
                  <Link to={"/booking"}>
                    <FormattedMessage id={"buttonInPracticeCard"} />
                  </Link>
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      )}
      {mobileView && (
        <div className="practicesBox">
          <Carousel
            swipeable={true}
            draggable={false}
            responsive={responsive}
            ssr={false}
            infinite={true}
            autoPlay={false}
            partialVisible={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            itemClass="practiceCardCarousel"
            removeArrowOnDeviceType={["mobile"]}
          >
            {practices.map((type, index) => (
              <div key={"practice" + index} className="practiceCardContent">
                <Card
                  className="practiceCard"
                  key={"practiceCard" + index}
                  style={{ backgroundImage: `url(${type.img})` }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="practiceCardTitle"
                    >
                      <FormattedMessage id={`${type.title}`} />
                    </Typography>
                  </CardContent>
                  <CardActions className="practiceCardFooter">
                    <Typography
                      variant="body2"
                      className="practiceCardDescription"
                    >
                      <FormattedMessage id={type.description} />
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      className="buttonInPracticeCard"
                    >
                      <Link to={"/booking"}>
                        <FormattedMessage id={"buttonInPracticeCard"} />
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}
