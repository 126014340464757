export const practicesType = [
  {
    id: 1,
    title: "pricesPersonalLesson",
    titleForPay: "Персональный класс",
    cost: "100€",
  },
  {
    id: 2,
    title: "pricesPersonalLessonZoom",
    titleForPay: "Персональный класс в zoom",
    cost: "70€",
  },
  {
    id: 3,
    title: "pricesPrenatal",
    titleForPay: "Персональный класс Prenatal",
    cost: "100€",
  },
  {
    id: 4,
    title: "pricesPrenatalZoom",
    titleForPay: "Персональный класс Prenatal в zoom",
    cost: "70€",
  },
  {
    id: 5,
    title: "pricesGroupPractice",
    titleForPay: "Групповой класс",
    cost: "40€",
  },
  {
    id: 6,
    title: "pricesGroupPracticeZoom",
    titleForPay: "Групповой класс в zoom",
    cost: "25€",
  },
  {
    id: 7,
    title: "subscription4practices",
    titleForPay: "Абонемент на 4 класса",
    cost: "80€",
  },
  {
    id: 8,
    title: "subscription8practices",
    titleForPay: "Абонемент на 8 классов",
    cost: "150€",
  },
  {
    id: 9,
    title: "subscription12practices",
    titleForPay: "Абонемент на 12 классов",
    cost: "180€",
  },
  {
    id: 10,
    title: "subscription16practices",
    titleForPay: "Абонемент на 16 классов",
    cost: "200€",
  },
];
