import { createDocument, getDocuments } from "../firebase";

export const createBooking = async (date, time, user) => {
  try {
    const templateParams = {
      name: user.displayName,
      recipient: user.email,
      message:
        "You have successfully registered for yoga classes on " +
        date +
        " " +
        "at " +
        time,
    };

    const templateForDariya = {
      to_name: "Йогиня Дарья",
      message:
        "Новое бронирование от " +
        user.displayName +
        " " +
        user.email +
        " " +
        "дата: " +
        date +
        " " +
        "время: " +
        time,
    };
    // eslint-disable-next-line no-undef
    emailjs.send("service_tjucz4v", "template_ow479qe", templateParams);
    // eslint-disable-next-line no-undef
    emailjs.send("service_tjucz4v", "template_lh63o9s", templateForDariya);

    return await createDocument("bookings", { date, time, userId: user.uid, userName: user.displayName });
  } catch (error) {
    console.error(error);
  }
};

export const showBookings = async (id) => {
  if (!id) return;

  try {
    const allBookings = await getDocuments("bookings");
    const bookings = allBookings.filter((b) => b.userId === id);

    const prevBookingsOfUser = bookings.filter(
      (book) => getTimeInMls(book.date) < getTimeInMls()
    );
    const nextBookingsOfUser = bookings.filter(
      (book) => getTimeInMls(book.date) > getTimeInMls()
    );
    return { prevBookingsOfUser, nextBookingsOfUser };
  } catch (error) {
    console.error(error);
  }
};

export const showAllBookings = async () => {
  try {
    return await getDocuments("bookings");
  } catch (error) {
    console.error(error);
  }
};

function getTimeInMls(date) {
  return date ? new Date(date).getTime() : Date.now();
}
